import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Image,
  Typography,
  Divider,
  Select,
  Anchor,
  Radio,
  Spin,
  InputNumber,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useParams, useHistory, Link } from "react-router-dom";
import API from "../../../shared/api/api";
import axios from "axios";
import moment from "moment";
import {
  DRIVER_TYPE,
  LICENSE_TYPE_OPTIONS,
  SEX_OPTION,
} from "../../../shared/define";
import AddressForm from "../../../shared/Components/AddressForm.tsx/AddressForm";
import MomentDatePicker from "../../../Components/MomentDatePicker";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Title } = Typography;
const { Option } = Select;
const { Link: LinkURL } = Anchor;
function DetailDriver() {
  let { id }: any = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [driverData, setDriverData] = useState<any>({});
  const [cityList, setCityList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [spinning, setSpinning] = useState(true);

  useEffect(() => {
    getListCity();
    getListVendor();
    loadData();
  }, []);
  const getListVendor = () => {
    API.get("admin/truck-vendor/all")
      .then((res) => {
        const { data, success } = res.data;
        if (success) {
          const listData = data;
          listData.forEach((item: { key: any; _id: any }) => {
            item.key = item._id;
          });
          setVendorList(listData.sort((a: any, b: any) => a.shortName.localeCompare(b.shortName)));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const loadData = () => {
    API.get(`admin/driver/${id}`)
      .then((res) => {
        const { success, data } = res.data;
        if (success) {
          let listData = data;
          listData = {
            ...listData,
            license: {
              ...listData.license,
              date: moment(listData.license?.date),
            },
            dateOfBirth: moment(listData.dateOfBirth),
          };
          setDriverData(listData);
          form.setFieldsValue(listData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSpinning(false);
      });
  };
  const getListCity = () => {
    axios
      .get("https://vapi.vnappmob.com/api/province")
      .then((res: any) => {
        if (res.status === 200) {
          setCityList(res.data.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Row style={{ width: "100%" }}>
      <Spin spinning={spinning}>
        <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
          <span
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined />
            Quay lại
          </span>
        </Title>
        <Form form={form} {...layout} style={{ width: "100%" }}>
          <Row gutter={[32, 32]}>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="SDT" name={["owner", "phoneNumber"]}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Mật khẩu" name="password">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Loại tài xế" name="type">
                <Select disabled>
                  {DRIVER_TYPE.map((item: any, index: any) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item label="Ảnh tài xế" name="avatar">
                <Image
                  height={150}
                  width={150}
                  src={driverData.avatar ? driverData.avatar : "/id.png"}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={16} md={16} lg={16} xl={16}>
              <Row gutter={32}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item label="Tên" name="name">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Tên doanh nghiệp"
                    name={["truckVendor", "_id"]}
                  >
                    <Select
                      disabled
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {vendorList.map((vendor: any) => (
                        <Option key={vendor._id} value={vendor._id}>
                          {vendor.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Form.Item label="Giới tính" name="sex">
                    <Radio.Group disabled>
                      {SEX_OPTION.map((item: any) => (
                        <Radio value={item.value}>{item.label}</Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Form.Item label="Ngày sinh" name="dateOfBirth">
                    <MomentDatePicker disabled format="DD/MM/YYYY" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item label="Mã tài xế" name="code">
                    <Input placeholder="Nhập mã tài xế" disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Divider />
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label="CMND/CCCD Người đại diện"
                    name={["IDCard", "number"]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                {driverData.IDCard?.front && (
                  <Col xs={24} sm={12}>
                    <Form.Item name={["IDCard", "front"]} label="Mặt trước">
                      <Image
                        width={120}
                        height={120}
                        src={
                          driverData.IDCard?.front
                            ? driverData.IDCard?.front
                            : "/id.png"
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
                {driverData.IDCard?.back && (
                  <Col xs={24} sm={12}>
                    <Form.Item name={["IDCard", "back"]} label="Mặt sau">
                      <Image
                        width={120}
                        height={120}
                        src={
                          driverData.IDCard?.front
                            ? driverData.IDCard?.front
                            : "/id.png"
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item label="Số bằng lái" name={["license", "number"]}>
                    <Input disabled />
                  </Form.Item>
                </Col>
                {driverData.license?.front && (
                  <Col xs={24} sm={12}>
                    <Form.Item name={["license", "front"]} label="Mặt trước">
                      <Image
                        width={120}
                        height={120}
                        src={
                          driverData.license?.front
                            ? driverData.license?.front
                            : "/id.png"
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
                {driverData.license?.back && (
                  <Col xs={24} sm={12}>
                    <Form.Item name={["license", "back"]} label="Mặt sau">
                      <Image
                        width={120}
                        height={120}
                        src={
                          driverData.license?.back
                            ? driverData.license?.back
                            : "/id.png"
                        }
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item label="Loại bằng" name={["license", "type"]}>
                    <Select
                      disabled
                      style={{ width: "100%" }}
                      placeholder="Search to Select"
                    >
                      {LICENSE_TYPE_OPTIONS.map((item: any, index: any) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item label="Thời hạn bằng" name={["license", "date"]}>
                    <MomentDatePicker
                      style={{ width: "100%" }}
                      disabled
                      format={"DD/MM/YYYY"}
                      onChange={(date, dateString) => {
                        console.log(dateString);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item label="Lương cứng" name="salary">
                    <InputNumber
                      disabled
                      formatter={(value) =>
                        ` ${value
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Divider />
            <AddressForm isEdit={true} />
            <Divider />
          </Row>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
            <Link
              to={{
                pathname: `/resource/driver/edit/${id}`,
              }}
            >
              <Button
                htmlType="submit"
                style={{
                  width: "100%",
                  backgroundColor: "#03A9F4",
                  color: "white",
                }}
              >
                Chỉnh sửa
              </Button>
            </Link>
          </Col>
        </Form>
      </Spin>
    </Row>
  );
}

export default DetailDriver;
