import React, {useEffect, useState} from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Upload,
  Button,
  Radio,
  DatePicker,
  Divider,
  Select,
  Typography,
  Space,
  InputNumber,
} from "antd";
import ImgCrop from "antd-img-crop";
import {LeftOutlined} from "@ant-design/icons";
import API from "../../../shared/api/api";
import {errorNotification} from "../../../shared/notification";
import {dateFormatYMD} from "../../../shared/dataFormat";
import {useHistory} from "react-router-dom";
import {rule_require, rule_number} from "../../../shared/validateInput";
import {
  beforeUpload,
  onPreview,
  propsImage,
} from "../../../shared/imageHandle";
import UserInfoForm from "../../../shared/Components/UserInfoForm/UserInfoForm";
import AddressForm from "../../../shared/Components/AddressForm.tsx/AddressForm";
import {LICENSE_TYPE_OPTIONS} from "../../../shared/define";
import MomentDatePicker from "../../../Components/MomentDatePicker";

const layout = {
  labelCol: {span: 24},
  wrapperCol: {span: 24},
};
const {Title, Text} = Typography;
const {Option} = Select;

function CreateDriver() {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any>([]);
  const [idFront, setIdFront] = useState<any>([]);
  const [idBack, setIdBack] = useState<any>([]);
  const [licenseFront, setLicenseFront] = useState<any>([]);
  const [licenseBack, setLicenseBack] = useState<any>([]);
  const [vendorList, setVendorList] = useState([]);
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [driverType, setDriverType] = useState("DRIVER");

  const history = useHistory();
  useEffect(() => {
    getListVendor();
  }, []);
  const getListVendor = () => {
    API.get("admin/truck-vendor/all")
      .then((res) => {
        const {data, success} = res.data;
        if (success) {
          const listData = data;
          listData.forEach((item: { key: any; _id: any }) => {
            item.key = item._id;
          });
          setVendorList(listData.sort((a: any, b: any) => a.shortName.localeCompare(b.shortName)));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChange = ({fileList: newFileList}: { fileList: any }) => {
    setFileList(newFileList);
  };
  const parseData = () => {
    let customdata = {...form.getFieldsValue()};
    customdata = {
      ...customdata,
      IDCard: {
        ...customdata.IDCard,
        front: idFront[0]?.response?.data.url,
        back: idBack[0]?.response?.data.url,
      },
      license: {
        ...customdata.license,
        front: licenseFront[0]?.response?.data.url,
        back: licenseBack[0]?.response?.data.url,
        ...(customdata.license?.date && {date: dateFormatYMD(customdata.license?.date)})
      },
      avatar: fileList[0]?.response.data.url,
      dateOfBirth: dateFormatYMD(customdata.dateOfBirth),
      email: form.getFieldValue(['owner', 'email']),
      phoneNumber: form.getFieldValue(['owner', 'phoneNumber']),
      password: form.getFieldValue('password'),
    }
    return customdata
  }
  const onCreate = async () => {
    // if (!parseData().avatar) {
    //     errorNotification('Yêu cầu ảnh đại diện')
    //     setLoadingBtn(false)
    // }
    // else {
    const response = await API.post('admin/driver', parseData())
      .then(res => res.data)
    if (response.success) history.push('/resource/driver');
    setLoadingBtn(false);
    // }
  }
  return (
    <Row style={{width: "100%"}}>
      <Title level={5} style={{marginBottom: "25px", cursor: "pointer"}}>
        <span
          onClick={() => {
            history.goBack();
          }}
        >
          <LeftOutlined/>
          Quay lại
        </span>
      </Title>
      <Form
        form={form}
        {...layout}
        initialValues={{address: {country: "Việt Nam"}}}
        style={{width: "100%"}}
        onFinish={onCreate}
      >
        <UserInfoForm
          hideEmail
          type="DRIVER"
          driverType={{
            type: driverType,
            setType: (value: any) => setDriverType(value),
          }}
        />
        <Divider/>
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Form.Item
              label="Ảnh tài xế"
              name="avatar"
              extra={
                <Space direction="vertical">
                  <Text>Ảnh không quá 1mb</Text>
                  <Text>Size 200x200px</Text>
                </Space>
              }
            >
              <ImgCrop rotate>
                <Upload
                  listType="picture-card"
                  {...propsImage}
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                  beforeUpload={beforeUpload}
                >
                  {fileList.length < 1 && "+ Upload"}
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Col>
          <Col xs={24} sm={16} md={16} lg={16} xl={16}>
            <Row gutter={32}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item label="Tên" name="name" rules={[rule_require]}>
                  <Input placeholder="Nhập tên"/>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Tên nhà xe"
                  name={["truckVendor", "_id"]}
                  rules={[rule_require]}
                >
                  <Select
                    showSearch
                    style={{width: "100%"}}
                    placeholder="Chọn doanh nghiệp"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {vendorList.map((vendor: any) => (
                      <Option key={vendor._id} value={vendor._id}>
                        {vendor.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <Form.Item label="Giới tính" name="sex" rules={[rule_require]}>
                  <Radio.Group>
                    <Radio value="MALE">Nam</Radio>
                    <Radio value="FEMALE">Nữ</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <Form.Item
                  label="Ngày sinh"
                  name="dateOfBirth"
                  rules={[rule_require]}
                >
                  <MomentDatePicker
                    style={{width: "100%"}}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              </Col>
              {driverType == "DRIVER" && (
                <Col xs={24} sm={12}>
                  <Form.Item label="Mã tài xế" name="code">
                    <Input placeholder="Nhập mã tài xế"/>
                  </Form.Item>
                </Col>
              )}
              {driverType == "DELIVERY" && (
                <Col xs={24} sm={12}>
                  <Form.Item label="Mã giao nhận" name="code">
                    <Input placeholder="Nhập mã người giao nhận"/>
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} sm={12}>
                <Form.Item
                  label="Lương cứng"
                  name="salary"
                  rules={[rule_number]}
                >
                  <InputNumber
                    formatter={(value) =>
                      ` ${value
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                    }
                    style={{width: "100%"}}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Divider/>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Col xs={24}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    label="CMND/CCCD Người đại diện"
                    name={["IDCard", "number"]}
                    rules={[rule_require, rule_number]}
                    labelCol={{span: 24}}
                  >
                    <Input placeholder="Nhập số CMND/CCCD"/>
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name={["IDCard", "front"]}
                    labelCol={{span: 24}}
                    label="Mặt trước"
                  >
                    <ImgCrop aspect={3 / 2} rotate>
                      <Upload
                        listType="picture-card"
                        {...propsImage}
                        fileList={idFront}
                        onChange={({
                                     fileList: newFileList,
                                   }: {
                          fileList: any;
                        }) => {
                          setIdFront(newFileList);
                        }}
                        onPreview={onPreview}
                        beforeUpload={beforeUpload}
                      >
                        {idFront.length < 1 && "+ Upload"}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    name={["IDCard", "back"]}
                    labelCol={{span: 24}}
                    label="Mặt sau"
                  >
                    <ImgCrop aspect={3 / 2} rotate>
                      <Upload
                        listType="picture-card"
                        {...propsImage}
                        fileList={idBack}
                        onChange={({
                                     fileList: newFileList,
                                   }: {
                          fileList: any;
                        }) => {
                          setIdBack(newFileList);
                        }}
                        onPreview={onPreview}
                        beforeUpload={beforeUpload}
                      >
                        {idBack.length < 1 && "+ Upload"}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Col xs={24}>
              <Row>
                {driverType == "DRIVER" && (
                  <>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        label="Số bằng lái"
                        name={["license", "number"]}
                        rules={[rule_require, rule_number]}
                        labelCol={{span: 24}}
                      >
                        <Input placeholder="Nhập số bằng lái"/>
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name={["license", "front"]}
                        labelCol={{span: 24}}
                        label="Mặt trước"
                      >
                        <ImgCrop aspect={3 / 2} rotate>
                          <Upload
                            listType="picture-card"
                            {...propsImage}
                            fileList={licenseFront}
                            onChange={({
                                         fileList: newFileList,
                                       }: {
                              fileList: any;
                            }) => {
                              setLicenseFront(newFileList);
                            }}
                            onPreview={onPreview}
                            beforeUpload={beforeUpload}
                          >
                            {licenseFront.length < 1 && "+ Upload"}
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name={["license", "back"]}
                        label="Mặt sau"
                        labelCol={{span: 24}}
                      >
                        <ImgCrop aspect={3 / 2} rotate>
                          <Upload
                            listType="picture-card"
                            {...propsImage}
                            fileList={licenseBack}
                            onChange={({
                                         fileList: newFileList,
                                       }: {
                              fileList: any;
                            }) => {
                              setLicenseBack(newFileList);
                            }}
                            onPreview={onPreview}
                            beforeUpload={beforeUpload}
                          >
                            {licenseBack.length < 1 && "+ Upload"}
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8} xl={8}>
            <Row>
              {driverType == "DRIVER" && (
                <>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      label="Loại bằng"
                      name={["license", "type"]}
                      rules={[rule_require]}
                    >
                      <Select
                        style={{width: "100%"}}
                        placeholder="Search to Select"
                      >
                        {LICENSE_TYPE_OPTIONS.map((item: any, index: any) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      label="Thời hạn bằng"
                      name={["license", "date"]}
                      rules={[rule_require]}
                    >
                      <MomentDatePicker
                        style={{width: "100%"}}
                        format={"DD/MM/YYYY"}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </Col>
          <Divider/>
          <AddressForm/>
        </Row>
        <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
          <Button
            loading={isLoadingBtn}
            htmlType="submit"
            style={{
              width: "100%",
              backgroundColor: "#03A9F4",
              color: "white",
            }}
            onClick={() => {
              setLoadingBtn(true);
            }}
          >
            Thêm mới
          </Button>
        </Col>
      </Form>
    </Row>
  );
}

export default CreateDriver;
