import React, { useEffect, useState } from "react";
import API from "../../../shared/api/api";
import {
  Button,
  Col, Form,
  Input, Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import {
  EditOutlined,
  EnvironmentOutlined,
  FileExcelOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import AssignBot from "./AssignBot";
import * as FileSaver from "file-saver";
import moment from "moment";
import layout from "antd/es/layout";

const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;

function PriceList() {
  const defaultImportingFile = {
    imported: false,
    fileToImport: '',
    countAdded: 0,
    countUpdate: 0,
    updatedErrorList: [],
    addedErrorList: []
  };
  const {TextArea} = Input;
  const [priceList, setPriceList] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({});
  const [customerList, setCustomerList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [drawerVisible, setDrawerVisible] = useState<Boolean>(false);
  const [id, setId] = useState("");
  const [route, setRoute] = useState("");

  const [checkingFile, setCheckingFile] = useState(false)
  const [executingImportFile, setExecutingImportFile] = useState(false)
  const [fileToImport, setFileToImport] = useState(defaultImportingFile)
  const [fileImported, setFileImported] = useState(defaultImportingFile)
  const [importModal, setImportModal] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    loadlist();
  }, [filter, pagination.current, pagination.pageSize]);

  useEffect(() => {
    // getListProvince();
    getProvinces();
    getCustomers();
  }, []);

  const loadlist = () => {
    setIsLoading(true);
    API.get("admin/freight", {
      params: {
        ...filter,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
    })
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data;
          listData.forEach((item: { key: any; _id: any }) => {
            item.key = item._id;
          });
          setPriceList(listData);
          setPagination({ ...pagination, total: res.data.totalItem });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getListProvince = () => {
    axios
      .get("https://vapi.vnappmob.com/api/province")
      .then((res: any) => {
        if (res.status === 200) {
          setProvinceList(res.data.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProvinces = () => {
    API.get("admin/region/province", {
      params: {},
    })
      .then((res) => {
        if (res.data.success) {
          setProvinceList(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const getCustomers = () => {
    API.get("admin/customer/all", {})
      .then((res) => {
        if (res.data.success) {
          setCustomerList(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const columns: Array<object> = [
    {
      title: "STT",
      dataIndex: "_id",
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Khách hàng",
      dataIndex: "customer",
      render: (customer: any) => (
        <Tooltip
          placement="topLeft"
          title={`${customer.name} (${customer.code})`}
        >
          <div className="address">
            {customer.name} ({customer.code})
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Mã nhận",
      dataIndex: ["receipt", "code"],
    },
    {
      title: "Điểm nhận",
      dataIndex: "receipt",
      render: (receipt: any) => (
        <Tooltip
          placement="topLeft"
          title={`${receipt.name} -- ${receipt.pName}`}
        >
          <div className="address">
            {receipt.name} -- {receipt.pName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Mã trả",
      dataIndex: ["delivery", "code"],
    },
    {
      title: "Điểm trả",
      dataIndex: "delivery",
      render: (delivery: any) => (
        <Tooltip
          placement="topLeft"
          title={`${delivery.name} -- ${delivery.pName}`}
        >
          <div className="address">
            {delivery.name} -- {delivery.pName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Mô tả",
      dataIndex: "description",
    },
    {
      title: "Khoảng cách",
      dataIndex: "distance",
      render: (distance: any) => distance + "km",
    },
    {
      title: "Có hàng",
      dataIndex: "haveGoodsDistance",
      render: (haveGoodsDistance: any) => Math.floor(haveGoodsDistance) + "km",
    },
    {
      title: "Không hàng",
      dataIndex: "noGoodsDistance",
      render: (noGoodsDistance: any) => Math.floor(noGoodsDistance) + "km",
    },
    {
      title: "Giá container 20",
      dataIndex: ["price", "cont20"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Giá container 40",
      dataIndex: ["price", "cont40"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Giá CY 20",
      dataIndex: ["price", "cy20"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Giá CY 40",
      dataIndex: ["price", "cy40"],
      render: (price: any) =>
        price
          ? price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Tiền đi đường",
      dataIndex: "salary",
      render: (salary: any) =>
        salary
          ? salary?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Tiền đi đường (kết hợp)",
      dataIndex: "salaryCombined",
      render: (salaryCombined: any) =>
        salaryCombined
          ? salaryCombined?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "đ"
          : "---",
    },
    {
      title: "Trạm thu phí",
      dataIndex: "bots",
      render: (text: any, record: any) => (
        <>
          <Button>{record.bots ? record.bots.length : "0"}</Button>{" "}
          <Button
            onClick={() => {
              setDrawerVisible(true);
              setId(record._id);
              setRoute(
                record.receipt.province + "-" + record.delivery.province
              );
            }}
          >
            <PlusOutlined />
          </Button>
        </>
      ),
    },
    {
      title: "Tác vụ",
      render: (price: any) => (
        <Tooltip title="Sửa">
          <Link
            to={{
              pathname: `/route-with-path/edit/${price._id}`,
              // pathname: `/basic-route/edit/${price._id}`,
            }}
          >
            <EditOutlined style={{ fontSize: 16 }} />
          </Link>
        </Tooltip>
      ),
    },
  ];
  const preCheckImportFile = (options: any) => {
    const {onSuccess, onError, file} = options;
    const formData = new FormData();
    setCheckingFile(true);
    formData.append("file", file);
    API.post("admin/freight/import/pre-check", formData)
      .then((res) => {
        if (res.data.success) {
          onSuccess("Ok");
          console.log('response', res.data)
          setFileToImport(res.data?.data || defaultImportingFile)
        }
        setCheckingFile(false);
      })
      .catch((err) => {
        onError({err});
        console.log("Error: ", err);
        setCheckingFile(false);
      });
  };

  const onConfirmImport = async () => {
    if (!fileToImport.fileToImport) {
      alert('Chưa có file import')
      return;
    }
    const body = {
      fileName: fileToImport.fileToImport
    }
    setExecutingImportFile(true)
    API.post("admin/freight/import/confirm", body)
      .then((res) => {
        if (res.data.success) {
          setExecutingImportFile(false)
          setFileImported({...res.data?.data, imported: true})
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
        setExecutingImportFile(false)
      });
  };

  const exportData = (options: any) => {
    const { onSuccess, onError } = options;
    API.get("admin/freight/export", { responseType: "blob" })
      .then((res) => {
        console.log(res.data)
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        FileSaver.saveAs(blob, 'freight_export_' + moment().format("yyyyMMDD") + '.xlsx');
      })
      .catch((err) => {
        onError({ err });
        console.log("Error: ", err);
      });
  };
  const handleCloseModal = () => {
    setImportModal(false);
    setFileToImport(defaultImportingFile)
    // setSelectedItem(undefined);
    form.resetFields();
  };
  return (
    <>
      <AssignBot
        loadlist={loadlist}
        id={id}
        route={route}
        visible={{
          drawerVisible: drawerVisible,
          setDrawerVisible: (option: Boolean) => setDrawerVisible(option),
        }}
      />
      <Title level={4}>Quản lý cước doanh thu ({pagination.total})</Title>
      <Row justify="space-between" gutter={8}>
        <Col>
          <Space size={8}>
            Khách hàng
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              size="small"
              style={{ width: 180 }}
              placeholder="Search to Select"
              defaultValue="ALL"
              optionFilterProp="children"
              onSelect={(value: string) =>
                setFilter({ ...filter, customer: value })
              }
            >
              <Option key="0" value="ALL">
                Tất cả
              </Option>
              {customerList.map((customer: any) => (
                <Option key={customer._id} value={customer._id}>
                  {customer.name + "(" + customer.code + ")"}
                </Option>
              ))}
            </Select>
            Điểm nhận
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              size="small"
              style={{ width: 150 }}
              placeholder="Search to Select"
              defaultValue="ALL"
              optionFilterProp="children"
              onSelect={(value: string) =>
                setFilter({ ...filter, receipt: value })
              }
            >
              <Option key="0" value="ALL">
                Tất cả
              </Option>
              {provinceList.map((province: any) => (
                <Option key={province._id} value={province._id}>
                  {province.name}
                </Option>
              ))}
            </Select>
            Điểm trả
            <Select
              dropdownMatchSelectWidth={false}
              showSearch
              size="small"
              style={{ width: 150 }}
              placeholder="Search to Select"
              defaultValue="ALL"
              optionFilterProp="children"
              onSelect={(value: string) =>
                setFilter({ ...filter, delivery: value })
              }
            >
              <Option key="0" value="ALL">
                Tất cả
              </Option>
              {provinceList.map((province: any) => (
                <Option key={province._id} value={province._id}>
                  {province.name}
                </Option>
              ))}
            </Select>
            Hiển thị
            <Select
              style={{ width: 80 }}
              size="small"
              placeholder="Search to Select"
              defaultValue={pagination.pageSize}
              onSelect={(value: number) =>
                setPagination({ ...pagination, pageSize: value })
              }
            >
              {[10, 20, 30, 40, 50].map((pageSize: any) => (
                <Option key={pageSize} value={pageSize}>
                  {pageSize}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Space>
          <Button
            disabled={false}
            size="small"
            style={{backgroundColor: "#b217e5", borderColor: "#b217e5"}}
            type="primary"
            icon={<FileExcelOutlined/>}
            onClick={() => setImportModal(true)}
          >
            Import bảng giá
          </Button>
          <Button
            size="small"
            style={{ backgroundColor: "#002696", borderColor: "#001131" }}
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={exportData}
          >
            Xuất bảng giá
          </Button>
          {/*<Link*/}
          {/*  to={{*/}
          {/*    // pathname: '/route-with-path/create',*/}
          {/*    pathname: "/basic-route/create",*/}
          {/*  }}*/}
          {/*  style={{ color: "white" }}*/}
          {/*>*/}
          {/*  <Button*/}
          {/*    size="small"*/}
          {/*    style={{ backgroundColor: "#2E3D80", borderColor: "#2E3D80" }}*/}
          {/*    type="primary"*/}
          {/*    icon={<EnvironmentOutlined />}*/}
          {/*  >*/}
          {/*    Tạo chặng (cũ)*/}
          {/*  </Button>*/}
          {/*</Link>*/}
          <Link
            to={{
              pathname: "/route-with-path/create",
              // pathname: '/basic-route/create',
            }}
            style={{ color: "white" }}
          >
            <Button
              size="small"
              style={{ backgroundColor: "#2E3D80", borderColor: "#2E3D80" }}
              type="primary"
              icon={<EnvironmentOutlined />}
            >
              Tạo chặng
            </Button>
          </Link>
          <Link
            to={{
              pathname: "/attribute-route/create",
            }}
            style={{ color: "white" }}
          >
            <Button size="small" type="primary" icon={<PlusOutlined />}>
              Thuộc tính
            </Button>
          </Link>
        </Space>
      </Row>
      <br />
      <Table
        scroll={{ x: "max-content" }}
        loading={isloading}
        bordered={false}
        columns={columns}
        dataSource={priceList}
        pagination={pagination.total > pagination.pageSize && pagination}
        onChange={(value: any) =>
          setPagination({ ...pagination, current: value.current })
        }
      />
      <Modal
        open={importModal}
        style={{width: 600}}
        title={"Import dữ liệu Doanh thu"}
        onCancel={handleCloseModal}
        footer={null}
        closable={true}
        // onOk={() => {
        //   form.submit();
        //   setImportModal(false);
        // }}
      >
        <Form {...layout} form={form} onFinish={onConfirmImport} layout="horizontal">
          <Form.Item name="_id" label="id" hidden>
            <Input type="text" disabled hidden/>
          </Form.Item>
          <Row style={{padding: 10}}>
            <Col style={{marginRight: 10}}>
              <Upload showUploadList={false} customRequest={preCheckImportFile}>
                <Button
                  disabled={checkingFile}
                  size="small"
                  style={{backgroundColor: "#009688", borderColor: "#009688"}}
                  type="primary"
                  loading={checkingFile}
                  icon={<FileExcelOutlined/>}
                >
                  Chọn file import
                </Button>
              </Upload>
            </Col>
            {/*{checkingFile && <Spin/>}*/}
            <Col>
              <Form.Item label="File:">
                {fileToImport.fileToImport}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row style={{marginTop: -30}}>
                <Form.Item label="Thêm mới:" style={{flexDirection: 'row'}}>
                  {fileToImport.countAdded} bản ghi
                </Form.Item>
                {
                  fileToImport.addedErrorList.length > 0 &&
                  <Form.Item label="Lỗi:" style={{flexDirection: 'row', marginLeft: 50}}>
                    {fileToImport.addedErrorList.length} bản ghi
                  </Form.Item>
                }
              </Row>
              <Row style={{marginTop: -30}}>
                <Form.Item label="Cập nhật:" style={{flexDirection: 'row'}}>
                  {fileToImport.countUpdate} bản ghi
                </Form.Item>
                {
                  fileToImport.updatedErrorList.length > 0 &&
                  <Form.Item label="Lỗi:" style={{flexDirection: 'row', marginLeft: 50}}>
                    {fileToImport.updatedErrorList.length} bản ghi
                  </Form.Item>
                }
              </Row>
            </Col>
            <Col>
              <TextArea
                disabled={true}
                style={{height: 220, width: 500, resize: 'none', overflow: 'scroll', marginBottom: 10}}
                // value={fileToImport.addedErrorList.reduce((a, b) => a.code + ', ' + a.truckVendorName + ', ' + item.reason).join('\n')}
                value={fileImported.imported ?
                  (fileImported.addedErrorList.length > 0 ?
                    ('Lỗi thêm mới:' + fileImported.addedErrorList.map(item => {
                      return '\n' + (item.index + 1) + ' ' + item.code + ', ' + item.customer + ', ' + item.reason + ', ' + item.address
                    })) : '')
                  + (fileImported.updatedErrorList.length > 0 ?
                      ('\nLỗi cập nhật:' + fileImported.updatedErrorList.map(item => {
                        return '\n' + (item.index + 1) + ' ' + item.code + ', ' + item.customer + ', ' + item.reason + ', ' + item.address
                      })) : ''
                  )
                  :
                  (fileToImport.addedErrorList.length > 0 ?
                    ('Kiểm tra lỗi thêm mới:' + fileToImport.addedErrorList.map(item => {
                      return '\n' + (item.index + 1) + ' ' + item.code + ', ' + item.customer + ', ' + item.reason + ', ' + item.address
                    })) : '')
                  + (fileToImport.updatedErrorList.length > 0 ?
                      ('\nKiểm tra lỗi cập nhật:' + fileToImport.updatedErrorList.map(item => {
                        return '\n' + (item.index + 1) + ' ' + item.code + ', ' + item.customer + ', ' + item.reason + ', ' + item.address
                      })) : ''
                  )
                }
              />
            </Col>
          </Row>
          Kết quả Import:
          <Col>
            {
              fileImported.imported && <Form.Item label={'Thêm mới:'}>
                {fileImported.countAdded} thành công, {fileImported.addedErrorList.length} thất bại
              </Form.Item>}
          </Col>
          <Col style={{marginTop: -30}}>
            {
              fileImported.imported && <Form.Item label={'Cập nhật:'}>
                {fileImported.countUpdate} thành công, {fileImported.updatedErrorList.length} thất bại
              </Form.Item>}
          </Col>
          <Form.Item>
            <Button
              key="submit"
              disabled={checkingFile ||
                executingImportFile ||
                fileToImport.fileToImport.length === 0 ||
                fileToImport.addedErrorList.length !== 0 ||
                fileToImport.updatedErrorList.length !== 0
              }
              style={{backgroundColor: "#894bf5", borderColor: "#894bf5"}}
              type="primary"
              block
              loading={executingImportFile}
              onClick={() => {
                form.submit();
              }}
            >Xác nhận import</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default PriceList;
