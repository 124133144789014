import "./refuelOil.css";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { dateFormat } from "../../shared/dataFormat";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import layout from "antd/es/layout";
import {
  createRefueling,
  deleteRefueling,
  exportRefueling,
  getAllRefueling,
  getGasStation,
  getRemainingFuel,
  lockRefueling,
  updateRefueling,
} from "../../shared/api/Oil";
import moment from "moment";
import API from "../../shared/api/api";
import MomentDatePicker from "../../Components/MomentDatePicker";
import styled from "styled-components";
const { RangePicker } = MomentDatePicker;

export const RefuelOilList = () => {
  const [form] = Form.useForm();
  const [formExport] = Form.useForm();

  const [open, setOpen] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openLockModal, setOpenLockModal] = useState(false);
  const [lockDate, setLockDate] = useState<string>("");

  const [selectedItem, setSelectedItem] = useState<any>();

  const [refuelOil, setRefuelOil] = useState({
    list: [],
    loading: false,
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  });

  const [remainingFuel, setRemainingFuel] = useState({
    list: [],
    loading: false,
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
  });

  const [listGasStations, setListGasStations] = useState([]);
  const [listDriver, setListDriver] = useState([]);
  const [listVehicle, setListVehicle] = useState([]);

  const fetchDataRefueling = async () => {
    setRefuelOil({ ...refuelOil, loading: true });
    const response: any = await getAllRefueling({
      params: {
        current: refuelOil.pagination.current,
      },
    });
    if (response.success) {
      setRefuelOil({
        ...refuelOil,
        list: response?.data,
        loading: false,
        pagination: { ...refuelOil.pagination, total: response.totalItem },
      });
    }
  };

  const fetchDataGasStation = async () => {
    const response: any = await getGasStation({});
    setListGasStations(response.data);
  };

  const fetchDataRemainingFuel = async () => {
    setRemainingFuel({ ...remainingFuel, loading: true });
    const response: any = await getRemainingFuel();
    setRemainingFuel({
      ...remainingFuel,
      list: response?.data,
      loading: false,
    });
  };

  useEffect(() => {
    Promise.all([
      fetchDataRefueling(),
      fetchDataGasStation(),
      getListDriver(),
      getListVehicle(),
      fetchDataRemainingFuel(),
    ]);
    return () => {};
  }, []);

  const columnsRemaining = [
    {
      title: "STT",
      width: 50,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Biển số",
      dataIndex: "vehicle",
    },
    {
      title: "Ngày đổ dầu gần nhất",
      dataIndex: "date",
      render: (value: any) => dateFormat(value),
    },
    {
      title: "Tiêu hao đến hiện tại",
      dataIndex: "consumption",
    },
    {
      title: "Dầu còn lại",
      dataIndex: "remaining",
    },
  ];

  const columns = [
    {
      title: "STT",
      width: 50,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Biển số",
      dataIndex: ["vehicle", "licensePlate"],
    },
    {
      title: "Ngày đổ dầu",
      dataIndex: "date",
      render: (value: any) => dateFormat(value),
    },
    {
      title: "Số nhiên liệu đã dùng",
      dataIndex: ["refueling", "amount"],
    },
    {
      title: "Tác vụ",
      width: 100,
      render: (record: any) => {
        return (
          <Space size="middle">
            <Tooltip title="Sửa">
              <Button
                type="text"
                disabled={record.isLocked}
                onClick={() => {
                  const data = {
                    _id: record._id,
                    date: moment(record.date),
                    vehicle: record.vehicle._id,
                    driver: record.driver._id,
                    refueling: {
                      station: record.refueling.station._id,
                      amount: record.refueling.amount,
                    },
                  };
                  setOpen(true);
                  setSelectedItem(data);

                  form.setFieldsValue(data);
                }}
              >
                <EditOutlined style={{ fontSize: 16 }} />
              </Button>
            </Tooltip>
            <Popconfirm
              title="Bạn có muốn xóa?"
              onConfirm={async () => {
                await deleteRefueling(record?._id);
                await fetchDataRefueling();
              }}
              okText="Có"
              cancelText="Hủy"
            >
              <Button type="text" disabled={record.isLocked}>
                <DeleteOutlined
                  style={{ fontSize: 16, color: "#FF4E4F", cursor: "pointer" }}
                />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const getListDriver = async () => {
    try {
      const response = await API.get(`admin/driver`).then((res) => res.data);
      setListDriver(response.data);
    } catch (error) {
      throw error;
    }
  };

  const getListVehicle = async () => {
    try {
      const response = await API.get(`admin/vehicle`, { params: {ownership: 'INVESTMENT'}}).then((res) => res.data);
      setListVehicle(response.data);
    } catch (error) {
      throw error;
    }
  };

  const onSubmitForm = async () => {
    if (selectedItem) {
      // Update
      const response = await updateRefueling({ ...form.getFieldsValue() });
      if (response.success) fetchDataRefueling();
    } else {
      // Create
      const response = await createRefueling({ ...form.getFieldsValue() });
      if (response.success) fetchDataRefueling();
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedItem(undefined);
    form.resetFields();
  };

  const handleExport = async () => {
    const paramExport = formExport.getFieldsValue();
    await exportRefueling(paramExport);
    setOpenExportModal(false);
  };

  const handleLockRefueling = async () => {
    await lockRefueling({ date: lockDate });
    setOpenLockModal(false);
    setLockDate("");
    fetchDataRefueling();
  };

  return (
    <>
      <Row justify="space-between" gutter={6}>
        <Title level={4}>Quản lý đổ dầu</Title>
        <Space size={6}>
          <Button
            className="custom-button"
            onClick={() => {
              setOpenLockModal(true);
            }}
            type="text"
            size="small"
          >
            Chốt dầu
          </Button>
          <Button
            onClick={() => {
              setOpenExportModal(true);
            }}
            type="default"
            size="small"
          >
            Tải báo cáo
          </Button>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            type="primary"
            size="small"
            icon={<PlusOutlined />}
          >
            Tạo mới
          </Button>
        </Space>
      </Row>
      <br />
      <Row gutter={10}>
        <Col span={12}>
          <Table
            id="remaining"
            scroll={{x: "max-content"}}
            rowKey="_id"
            loading={remainingFuel.loading}
            columns={columnsRemaining}
            pagination={
              remainingFuel.pagination.total >
              remainingFuel.pagination.pageSize && remainingFuel.pagination
            }
            dataSource={remainingFuel.list}
            onChange={(value: any) =>
              setRefuelOil({
                ...remainingFuel,
                pagination: {
                  ...remainingFuel.pagination,
                  current: value.current,
                },
              })
            }
          />
        </Col>
        <Col span={12}>
          <Table
            id="refuel"
            scroll={{ x: "max-content" }}
            rowKey="_id"
            loading={refuelOil.loading}
            columns={columns}
            pagination={
              refuelOil.pagination.total > refuelOil.pagination.pageSize &&
              refuelOil.pagination
            }
            dataSource={refuelOil.list}
            onChange={(value: any) =>
              setRefuelOil({
                ...refuelOil,
                pagination: { ...refuelOil.pagination, current: value.current },
              })
            }
          />
        </Col>
      </Row>

      <Modal
        open={open}
        title={selectedItem ? "Cập nhật đổ dầu" : "Tạo đổ dầu"}
        onCancel={handleCloseModal}
        onOk={() => {
          form.submit();
          setOpen(false);
        }}
      >
        <Form {...layout} form={form} onFinish={onSubmitForm} layout="vertical">
          <Form.Item name="_id" label="id" hidden>
            <Input type="text" disabled hidden />
          </Form.Item>
          <Row style={{ padding: 10 }}>
            <Col span={12}>
              <Form.Item name="vehicle" label="Phương tiện">
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Chọn phương tiện"
                  fieldNames={{ value: "_id", label: "licensePlate" }}
                  options={listVehicle}
                  onSelect={(_value, option: any) => {
                    form.setFieldValue("driver", option?.driver?._id);
                  }}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="driver" label="Tài xế">
                <Select
                  disabled
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Chọn tài xế"
                  fieldNames={{ value: "_id", label: "name" }}
                  options={listDriver}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="date" label="Ngày">
                <MomentDatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["refueling", "station"]} label="Địa điểm">
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Chọn cây xăng"
                  fieldNames={{ value: "_id", label: "name" }}
                  options={listGasStations}
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={["refueling", "amount"]} label="Số lượng">
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        width={600}
        destroyOnClose={true}
        closable={true}
        title="Tải báo cáo"
        open={openExportModal}
        onCancel={() => setOpenExportModal(false)}
        onOk={() => {
          formExport.submit();
        }}
        okText={
          <>
            <VerticalAlignBottomOutlined />
            Tải xuống
          </>
        }
        cancelText="Hủy"
      >
        <Form
          {...layout}
          form={formExport}
          onFinish={() => {
            handleExport();
          }}
        >
          <Form.Item
            label="Thời gian"
            name="range"
            initialValue={[moment().startOf("month"), moment().endOf("month")]}
          >
            <RangePicker
              name="range"
              style={{ width: 250 }}
              ranges={{
                "Tháng này": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                "Tháng trước": [
                  moment().subtract(1, "month").startOf("month"),
                  moment().subtract(1, "month").endOf("month"),
                ],
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={300}
        destroyOnClose={true}
        closable={true}
        title="Chọn ngày chốt dầu"
        open={openLockModal}
        onCancel={() => setOpenLockModal(false)}
        onOk={handleLockRefueling}
        okText={
          <>
            <VerticalAlignBottomOutlined />
            Xác nhận
          </>
        }
        cancelText="Hủy"
        footer={
          <Space>
            <Button onClick={() => setOpenLockModal(false)}>Huỷ</Button>
            <Popconfirm
              title={`Bạn có chắc muốn chốt dầu vào ngày ${lockDate}?`}
              onConfirm={handleLockRefueling}
            >
              <Button type="primary" disabled={!lockDate}>
                Xác nhận
              </Button>
            </Popconfirm>
          </Space>
        }
      >
        <MomentDatePicker
          format={"YYYY-MM-DD"}
          style={{ width: "100%" }}
          onChange={(date) => setLockDate(moment(date).format("YYYY-MM-DD"))}
        />
      </Modal>
    </>
  );
};
