export const SEX_OPTION = [
    {
        value:'MALE',
        label:'Nam'
    },
    {
        value:'FEMALE',
        label:'Nữ'
    }
]
export const VEHICLE_OPTION = [
    {
        value:'CONTAINER',
        label:'Xe container'
    },
    {
        value:'TRUCK',
        label:'Xe tải'
    }
]
export const PRESERVATION_OPTIONS = [
    {
        value:'NORMAL',
        label:'Hàng thường'
    },
    {
        value:'COLD',
        label:'Hàng lạnh'
    },
    {
        value:'DANGER',
        label:'Hàng nguy hiểm'
    },
    {
        value:'OOG',
        label:'Hàng quá khổ'
    },
]
export const PRESERVATION_OPTIONS_ICON = [
    {
        value:'NORMAL',
        label:'/icon/normal.png'
    },
    {
        value:'COLD',
        label:'/icon/cold.png'
    },
    {
        value:'DANGER',
        label:'/icon/danger.png'
    },
    {
        value:'OOG',
        label:'/icon/overweight.png'
    },
]
export const PAYLOAD_OPTION = [
    {
        value:'<1',
        label:'Nhỏ hơn 1 tấn'
    },
    {
        value:'1-5',
        label:'1-5 tấn'
    },
    {
        value:'5-7',
        label:'5-7 tấn'
    },
    {
        value:'>7',
        label:'>7 tấn'
    }
]
export const NUMBER_OF_AXLES_OPTION = [
    {
        value:2,
        label:'2 trục'
    },
    {
        value:3,
        label:'3 trục'
    },
    {
        value:4,
        label:'4 trục'
    }
]
export const NUMBER_OF_FEETS_OPTIONS = [
    {
        value:20,
        label:'20 feets'
    },
    {
        value: 40,
        label:'40 feets'
    },
    {
        value:45,
        label:'45 feets'
    }
]
export const ROUTE_STATUS_OPTIONS = [
    // {
    //     value: 'PENDING',
    //     label: 'Chưa lấy hàng'
    // },
    {
        value:'ASSIGNING',
        label:'Đang chỉ định'
    },
    {
        value:'PENDING',
        label: 'Chờ lấy hàng'
    },
    {
        value: 'START_PICK_UP_GOODS',
        label: 'Bắt đầu lấy hàng'
    },
    {
        value: 'TAKEN_GOODS',
        label: 'Đã lấy hàng'
    },
    {
        value: 'DROPPING_GOODS',
        label: 'Đang trả hàng'
    },
    {
        value: 'FINISH',
        label: 'Hoàn thành'
    },
    {
        value: 'CANCEL',
        label: 'Bị hủy'
    }
]
export const LICENSE_TYPE_OPTIONS = ['B1', 'B2', 'C', 'D', 'E', 'F']
export const FREIGHT_ROAD_OPTIONS = [
    {
        value:'ROAD',
        label:'Đường bộ'
    },
    {
        value:'WATERWAY',
        label:'Đường thủy'
    }
]
export const INSURANCE_VALUE_OPTION = [
    {
        value:'>5',
        label:'Lớn hơn 5 tỉ'
    },
    {
        value:'<5',
        label:'Nhỏ hơn 5 tỉ'
    }
]
export const ORIGIN_OPTION = [
    {
        value:'LOCAL',
        label:'Hàng nội'
    },
    {
        value:'FOREIGN',
        label:'Hàng ngoại'
    },
]
export const DANGER_OPTION = ['1-3','4-6','7-9']
export const ORDER_STATUS_OPTIONS = [
    {
        value:'PENDING',
        label:'Chờ duyệt'
    },
    {
        value:'ASSIGNING',
        label:'Đang chỉ định'
    },
    {
        value:'SHIPPING',
        label:'Đang vận chuyển'
    },
    {
        value:'FINISH',
        label:'Hoàn thành'
    },
    {
        value:'CANCEL',
        label:'Bị hủy'
    }
]

export const FORBIDDEN_OPTIONS = [
    {
        value:'UNKNOWN',
        label:'Không biết'
    },
    {
        value:'NOT_FORBIDDEN',
        label:'Đường không cấm'
    },
    {
        value:'FORBIDDEN',
        label:'Đường cấm'
    },
]

// - Loại mooc "type" nằm trong danh sách: 'NORMAL', 'SHORT', 'FLOOR', 'COLD', 'BEN', 'CAGE', 'CHECK', 'MEZZANINE', 'OTHER' (thường, lồn, sàn, lạnh, ben, lồng, téc, thùng lửng, khác)
// - Loại giảm sóc "shockAbsorber' nằm trong danh sách 'TWEEZERS', 'AIR_BALL', 'OTHER' (nhíp, bóng hơi, khác)
// Cả 2 đều ở tầng cao nhất
export const TRAILER_TYPE = [
    {
        value:'NORMAL',
        label:'Thường'
    },
    {
        value:'SHORT',
        label:'Lùn'
    },
    {
        value:'FLOOR',
        label:'Sàn'
    },
    {
        value:'COLD',
        label:'Lạnh'
    },
    {
        value:'BEN',
        label:'Ben'
    },
    {
        value:'CAGE',
        label:'Lồng'
    },
    {
        value:'TANK',
        label:'Téc'
    },
    {
        value:'MEZZANINE',
        label:'Thùng lửng'
    },
    {
        value:'OTHER',
        label:'Khác'
    }
]
export const SHOCKABSORBER_TYPE = [
    {
        value :'TWEEZERS',
        label:'Nhíp'
    },
    {
        value :'AIR_BALL',
        label:'Bóng'
    },
    {
        value :'OTHER',
        label:'Khác'
    },
]

export const SPECIFICATION_TYPE = [
    {
        value :'IMPORT',
        label:'Nhập',
        shortLabel: 'N'
    },
    {
        value :'EXPORT',
        label:'Xuất',
        shortLabel: 'X'
    },
    {
        value :'ROAD',
        label:'Bộ',
        shortLabel: 'B'
    },
]

export const COMBINE_TYPE = [
    {
        value :'COMBINE',
        label:'Kết hợp'
    },
    {
        value :'PULL',
        label:'Kéo vỏ'
    },
]
export const TRUCK_VENDOR_TYPE = {
    OWNER: 'OWNER',
    CONTRACTOR: 'CONTRACTOR'
}
export const TRUCK_VENDOR_TYPE_DISPLAY = [
    {
        value : TRUCK_VENDOR_TYPE.OWNER,
        label: 'Sở hữu'
    },
    {
        value : TRUCK_VENDOR_TYPE.CONTRACTOR,
        label: 'Nhà thầu'
    },
]

export const COMBINE_RATE = [1,0.9,0.7,0.6,0.65,0.5,'CY']

export const DELIVERY_TYPE = [
    {
        value:'FULL_SEAL',
        label:'Nguyên cont , nguyên chì'
    },
    {
        value:'TALLY',
        label:'Kiểm đếm'
    }
]

export const DRIVER_TYPE = [
    {
        value:'DRIVER',
        label:'Tài xế'
    },
    {
        value:'DELIVERY',
        label:'Giao nhận'
    }
]

export const REPAIR_TYPE = [
    {
        value:'BIG',
        label:'Lớn'
    },
    {
        value:'SMALL',
        label:'Nhỏ'
    },
    {
        value:'TIRE',
        label:'Xăm lốp'
    },
    {
        value:'TOOLS',
        label:'Công cụ, dụng cụ'
    },
]

export const OWNERSHIP_TYPE = [
    {
        value:'INVESTMENT',
        label:'Đầu tư'
    },
    {
        value:'DRY_RENTAL',
        label:'Thuê khô'
    },
    {
        value:'WET_RENTAL',
        label:'Thuê ướt'
    },
    {
        value:'SERVICE',
        label:'Dịch vụ'
    }
]

export const ASSIGN_TYPE = [
    {
        value:true,
        label:'Khoán'
    },
    {
        value:false,
        label:'Không khoán'
    },
]

export const ACTIVE_TYPE = [
    {
        value:true,
        label:'Hoạt động'
    },
    {
        value:false,
        label:'Không hoạt động'
    },
]
