import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Button,
  Row,
  Input,
  Typography,
  Tooltip,
  Modal,
  Descriptions,
  Alert,
  Col,
  Select,
  Upload,
  DatePicker,
  Tabs,
  message, Form,
} from "antd";
import API from "../../../shared/api/api";
import {
  CopyOutlined,
  FileExcelOutlined,
  ReloadOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  VerticalAlignBottomOutlined,
  EnterOutlined,
} from "@ant-design/icons";
import {
  COMBINE_RATE,
  PRESERVATION_OPTIONS,
  PRESERVATION_OPTIONS_ICON, TRUCK_VENDOR_TYPE,
} from "../../../shared/define";
import moment from "moment";
import styled from "styled-components";
import {
  assignTruckVendorRoute,
  cancelAssigningOrder, setExpenseAmountForOrder,
  getAllAssigningOrder,
  verifyAssigningOrder,
} from "../../../shared/api/Order/AssigningOrder";
import { getAllTruckVendor } from "../../../shared/api/TruckVendor";
import { importExcel } from "../../../shared/api/Order/PendingOrder";
import { successNotification } from "../../../shared/notification";
import {CombinationList} from "../../Order/PendingOrder/OrderList/CombinationsList";

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

function OrderWithRouteList() {
  const combinedDefault = {
    combined: false,
    route: '',
    combinedRoute: '',
    combinedRate: 0.5,
    container: '',
    customer: '',
    date: new Date(),
  };

  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [showInputCustomPrice, setShowInputCustomPrice] = useState(false);
  const [updatingOrder, setUpdatingOrder] = useState(null);
  const [expenseAmount, setExpenseAmount] = useState(0);
  const [truckVendorId, setTruckVendorId] = useState(null);
  const [combined, setCombined] = useState(combinedDefault);
  const [truckVendor, setTruckVendor] = useState({
    list: [],
  });
  const [combinations, setCombinations] = useState<any[]>([]);
  // const [mapCombinationFreight, setMapCombinationFreight] = useState(new Map());
  // let mapCombinationFreight = new Map<string, any>();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [importModal, setImportModal] = useState({
    visible: false,
    success: [],
    total: 0,
    assigned: 0,
    errors: [],
  });

  const fetchData = async (firstTime = false) => {
    setLoading(true);
    const response: any = await getAllAssigningOrder({
      ...filter,
      current: pagination.current,
    });
    const listOrder = response.data ?? []
    setDataList(listOrder);
    // if (firstTime) {
    //   console.log('init mapcombination')
    //   for (let i = 0; i < listOrder.length; i++) {
    //     mapCombinationFreight.set(listOrder[i]._id, []);
    //   }
    //   setMapCombinationFreight(mapCombinationFreight);
    // }
    setPagination({ ...pagination, total: response.total });
    setLoading(false);
  };

  useEffect(() => {
    fetchTruckVendor();
  }, []);
  useEffect(() => {
    fetchData(true);
  }, [filter, pagination.current]);
  const cancelOrder = async (id: string) => {
    const response = await cancelAssigningOrder(id);
    if (response.success) fetchData();
  };
  const verifyOrder = async (orderId: string) => {
    setLoading(true);
    const response = await verifyAssigningOrder(orderId);
    console.log(response)
    if (response.success) {
      setCombined(response.data)
      await fetchData();
    }
    setLoading(false);
  };
  const handleCombinedRoute = async () => {
    const response = await API.put(
      `admin/route/${combined.route}/combined/${combined.combinedRoute}`,
      { rate: combined.combinedRate }
    ).then((res) => res.data);
    if (response.success) {
      setCombined(combinedDefault);
    }
  };
  const fetchTruckVendor = async () => {
    const response: any = await getAllTruckVendor();
    setTruckVendor({
      list: response.data.sort((a: any, b: any) => a.shortName.localeCompare(b.shortName)),
    });
  };

  const assignTruckVendor = async (
    order: any,
    route: any,
    truckVendorId: any
  ) => {
    const response = await assignTruckVendorRoute({
      orderId: order._id,
      route: route,
      truckVendorId: truckVendorId,
    });
    if (response.success) {
      const initialExpenseAmount = response.data?.price;
      setExpenseAmount(initialExpenseAmount)
      const selectedTruckVendor = truckVendor.list.find(item => item._id === truckVendorId)
      console.log('Found truckVendor:', selectedTruckVendor);
      if (order.specification === 'ROAD' && selectedTruckVendor?.truckVendorType === TRUCK_VENDOR_TYPE.CONTRACTOR) {
        setShowInputCustomPrice(true)
        setUpdatingOrder(order);
      }
      await fetchData();
    }
  };
  const fetchFreightCombination = async (order: any, receiptId: any, deliveryId: any, truckVendor: any) => {
    console.log('Fetching contractorFreightCombination, orderId=', order?._id, 'truckVendor=', truckVendor)
    const searchParams = {
      receipt: receiptId,
      delivery: deliveryId,
      truckVendor: truckVendor
    };
    const response: any = await API.get("admin/contractorFreight/combination", {
      params: searchParams,
    });
    const { data } = response;

    if (data.success) {
      setCombinations(data?.data)
      setUpdatingOrder(order);
    }
  };
  const handleUpdatePrice = async (amount: number) => {
    if (updatingOrder) {
      const response = await setExpenseAmountForOrder({
        orderId: updatingOrder._id,
        amount: amount
      });
      setShowInputCustomPrice(false)
      if (response.success) {
        await fetchData();
      }
    }
  }

  const uploadExcel = async (options: any) => {
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append("file", file);
    const response: any = await importExcel(formData);
    if (response.success) {
      setImportModal({
        ...importModal,
        visible: true,
        success: response.success,
        errors: response.errors,
        total: response.total,
        assigned: response.assigned,
      });
      await fetchData();
    }
  };

  const verifyAll = async () => {
    let total = dataList.length;
    let success = 0;
    await Promise.all(
      dataList.map(async (order: any) => {
        if (order.routes[0].truckVendor) {
          let response = await verifyAssigningOrder(order._id, { all: true });
          if (response.success) success++;
        }
      })
    );
    successNotification(`Duyệt thành công ${success}/${total}`);
    await fetchData();
  };

  const columns: Array<object> = [
    {
      title: "STT",
      dataIndex: "_id",
      width: 40,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Ngày",
      dataIndex: "receipt",
      render: (receipt: any) => moment(receipt[0].time).format("DD/MM/YYYY"),
    },
    // {
    //   title: "Hãng tàu",
    //   dataIndex: ["shippingLines", "name"],
    // },
    {
      title: "Khách hàng",
      dataIndex: ["customer", "name"],
    },
    {
      title: "Số cont",
      dataIndex: ["vehicle", "container"],
    },
    {
      title: "Nhà xe",
      width: 300,
      dataIndex: "customRoutes",
      render: (customRoutes: any, record: any) => (
        <StyleWrapper>
          <Space>
            {customRoutes.routes.map((route: any) => (
              <div>
                <Button
                  style={{ backgroundColor: "#dcedff" }}
                  icon={
                    route.truckVendor && (
                      <img
                        alt="icon"
                        style={{
                          height: "12px",
                          width: "14px",
                          marginRight: "10px",
                        }}
                        src="/icon/truck.png"
                      />
                    )
                  }
                  key={
                    route.receipt?.[0]?.area?.pName +
                    route.delivery?.[0]?.area?.pName
                  }
                >
                  {route.receipt?.[0]?.area?.pName} -{" "}
                  {route.delivery?.[0]?.area?.pName}
                </Button>
                <br />
                <Select
                  suffixIcon={
                    route.truckVendor ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: 300,
                          position: "relative",
                          left: "-200%",
                        }}
                      >
                        {" "}
                        <CopyOutlined
                          onClick={() => {
                            setTruckVendorId(route.truckVendor);
                            message.info("Đã sao chép nhà xe");
                          }}
                        />{" "}
                        <EnterOutlined
                          onClick={() =>
                            assignTruckVendor(record, route, truckVendorId)
                          }
                        />
                      </div>
                    ) : (
                      <EnterOutlined
                        onClick={() =>
                          assignTruckVendor(record, route, truckVendorId)
                        }
                      />
                    )
                  }
                  value={route.truckVendor}
                  showSearch
                  style={{ width: 300 }}
                  placeholder="Nhà xe"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(value) =>
                    assignTruckVendor(record, route, value)
                  }
                >
                  {truckVendor.list.map((truckVendor: any) => (
                    <Option key={truckVendor._id} value={truckVendor._id}>
                      {truckVendor?.shortName}
                      {/*+*/}
                      {/*  "-" +*/}
                      {/*  truckVendor?.businessLicense?.text}*/}
                    </Option>
                  ))}
                </Select>
              </div>
            ))}
          </Space>
        </StyleWrapper>
      ),
    },
    {
      title: "Điểm nhận",
      dataIndex: ["receipt"],
      width: 300,
      render: (receipt: any) => {
        return receipt?.[0]?.text
      }
    },
    {
      title: "Điểm trả",
      dataIndex: ["delivery"],
      width: 300,
      render: (delivery: any) => {
        return delivery?.[0]?.text
      }
    },
    {
      title: "Mã tuyến (chi phí)",
      width: 150,
      dataIndex: ["freight"],
      render: (freight: any, record: any) => (
        <StyleWrapper>
          <Space>
            <div>
              <Button
                style={{ backgroundColor: "#dcedff", width: 180 }}
                icon={
                  <img
                    alt="icon"
                    style={{
                      height: "14px",
                      width: "14px",
                      marginRight: "10px",
                    }}
                    src="/icon/ic-refresh.png"
                  />
                }
                key={
                  freight?._id
                }
                disabled={!record.truckVendor || record.truckVendor.truckVendorType !== 'CONTRACTOR'}
                onClick={() => {
                  if (record.truckVendor._id) {
                    fetchFreightCombination(record, freight.receipt, freight.delivery, record.truckVendor._id)
                  }
                }}
              >
                Tải tuyến chi phí
              </Button>
              <br />
              <CombinationList
                // value={record.freight.code}
                disabled={!record.truckVendor || record.truckVendor.truckVendorType !== 'CONTRACTOR'}
                style={{width: 250}}
                showCustomer={false}
                listOptions={record._id === updatingOrder?._id ? combinations : []}
                onChange={async (freight) => {
                  const price = record.vehicle.name === 'CONTAINER' ? freight.price[`cont${record.vehicle.numberOfFeets}`] : freight.price.truck ?? 0
                  console.log('Selected', freight, 'with price', price)
                  await handleUpdatePrice(price)
                  // setSelectedFreight(combinations.find(freightItem => freightItem._id === freight))
                }}/>
            </div>
          </Space>
        </StyleWrapper>
      ),
    },
    {
      title: "Tên Hàng",
      dataIndex: ["goods", "name"],
    },
    {
      title: "Khối lượng",
      dataIndex: "goods",
      render: (goods: any) => goods?.volume + " Tấn",
    },
    {
      title: "Đặc tính",
      dataIndex: ["goods", "preservation"],
      render: (option: any) => {
        const tooltip = PRESERVATION_OPTIONS.find(
          (element: any) => (element as any).value === option
        )?.label;
        const icon = PRESERVATION_OPTIONS_ICON.find(
          (element: any) => (element as any).value === option
        )?.label;
        return (
          <Tooltip placement="topLeft" title={tooltip} arrowPointAtCenter>
            <img src={icon} />
          </Tooltip>
        );
      },
    },
    {
      title: "Doanh thu dự kiến",
      dataIndex: "price",
      render: (price: any) => {
        return price ? new Intl.NumberFormat().format(price) + "đ" : "Báo sau"
      }
    },
    {
      title: "Chi phí dự kiến",
      // dataIndex: "price",
      render: (order: any) => {
        const amount = order?.truckVendor?.truckVendorType === TRUCK_VENDOR_TYPE.CONTRACTOR ? order.expenseAmount : 0
        return new Intl.NumberFormat().format(amount) + "đ"
      }
    },
    {
      title: "Tác vụ",
      align: "center",
      render: (order: any) => (
        <Space size="middle">
          <Tooltip title="Duyệt">
            <CheckCircleOutlined
              style={{ color: "green", fontSize: 16 }}
              onClick={() => {
                verifyOrder(order._id);
              }}
            />
          </Tooltip>
          <Tooltip title="Hủy">
            <CloseCircleOutlined
              style={{ color: "red", fontSize: 16 }}
              onClick={() => {
                cancelOrder(order._id);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const importColumns: Array<object> = [
    {
      title: "STT",
      dataIndex: "stt",
      width: 50,
      // render: (id: any, record: any, index: any) => index + 1
    },

    {
      title: "Ngày",
      dataIndex: "date",
    },
    {
      title: "Số cont",
      dataIndex: "vehicleContainer",
    },
    {
      title: "N/X",
      dataIndex: "specification",
    },
    {
      title: "Mã chủ hàng",
      dataIndex: "forwarderBusinessLicense",
      render: (item: any) => (item ? item : "HAFC"),
    },
    {
      title: "Tên chủ hàng",
      dataIndex: "forwarderName",
      render: (item: any) => (item ? item : "HAFC"),
    },
    {
      title: "Mã chủ thầu",
      dataIndex: "truckVendorBusinessLicense",
      render: (item: any) => (item ? item : "HAFT"),
    },
    {
      title: "Tên chủ thầu",
      dataIndex: "truckVendorName",
      render: (item: any) => (item ? item : "HAFT"),
    },
    {
      title: "Mã nhận",
      dataIndex: "receiptCode",
    },
    {
      title: "Mã trả",
      dataIndex: "deliveryCode",
    },
  ];

  return (
    <>
      <Title level={4}>
        Chỉ định đơn ({pagination.total}) cho nhà xe{" "}
        <Tooltip title="Đơn đơn phương thức có 1 chặng. Chỉ định nhà xe cho đơn. Tạo các chặng cho đơn nếu là đơn đa phương thức.">
          <InfoCircleOutlined style={{ color: "#0082fb" }} />
        </Tooltip>
      </Title>
      <Row justify="space-between" gutter={6}>
        <Col>
          <Space size={6}>
            <Select
              size="small"
              placeholder="Loại hàng"
              style={{ width: 120 }}
              onChange={(value) =>
                setFilter({ ...filter, preservation: value })
              }
            >
              <Option value="ALL">Tất cả</Option>
              <Option value="NORMAL">Thường</Option>
              <Option value="COLD">Lạnh</Option>
              <Option value="DANGER">Nguy hiểm</Option>
              <Option value="OOG">Quá khổ</Option>
            </Select>
            <RangePicker
              size="small"
              style={{ width: 270 }}
              onChange={(date, dateString) =>
                setFilter({
                  ...filter,
                  range: [
                    moment.utc(date?.[0]).format("YYYY-MM-DD HH:mm:ssZ"),
                    moment.utc(date?.[1]).format("YYYY-MM-DD HH:mm:ssZ"),
                  ],
                })
              }
            />
            <Select
              size="small"
              placeholder="Loại container"
              style={{ width: 150 }}
              onChange={(value) =>
                setFilter({ ...filter, numberOfFeets: value })
              }
            >
              <Option value="ALL">Tất cả</Option>
              <Option value="20">20</Option>
              <Option value="40">40</Option>
              <Option value="45">45</Option>
            </Select>
            <Select
              size="small"
              placeholder="Nhập/Xuất/Bộ"
              style={{ width: 150 }}
              onChange={(value) =>
                setFilter({ ...filter, specification: value })
              }
            >
              <Option value="ALL">Tất cả</Option>
              <Option value="IMPORT">Nhập</Option>
              <Option value="EXPORT">Xuất</Option>
              <Option value="ROAD">Bộ</Option>
            </Select>
          </Space>
        </Col>
        <Space size={6}>
          <Button
            size="small"
            onClick={fetchData}
            icon={<ReloadOutlined />}
          ></Button>
          <Search
            placeholder="Tìm kiếm số Container"
            size="small"
            allowClear
            style={{ width: 250, margin: "0 10px" }}
            onSearch={(value) => setFilter({ ...filter, searchText: value })}
          />
          {/* <Button type="primary" icon={<PlusOutlined />}>
                        <Link to={{
                                pathname: '/order/pending/create',
                            }} style={{ color: 'white' }}>
                                Tạo mới
                        </Link>
                    </Button> */}
          <Tooltip title="Tải lên file đơn">
            <Upload showUploadList={false} customRequest={uploadExcel}>
              <Button
                size="small"
                style={{ backgroundColor: "#009688", borderColor: "#009688" }}
                type="primary"
                icon={<FileExcelOutlined />}
              >
                Tải lên
              </Button>
            </Upload>
          </Tooltip>
          <Tooltip title="Tải file mẫu">
            <a href="/files/don-hang-mau.xls" download>
              <Button
                type="primary"
                size="small"
                icon={<VerticalAlignBottomOutlined />}
              ></Button>
            </a>
          </Tooltip>
          <Button
            size="small"
            style={{ backgroundColor: "green", borderColor: "#00a239" }}
            type="primary"
            icon={<CheckCircleOutlined />}
            onClick={verifyAll}
          >
            Duyệt tất cả
          </Button>
        </Space>
      </Row>
      <br />
      <Table
        tableLayout="auto"
        scroll={{ x: "max-content" }}
        loading={loading}
        columns={columns}
        pagination={pagination.total > 10 && pagination}
        dataSource={dataList}
        onChange={(value: any) =>
          setPagination({ ...pagination, current: value.current })
        }
      />
      <Modal
        title="Đơn kết hợp"
        open={combined.combined}
        okText="Xác nhận"
        cancelText="Không phải đơn kết hợp"
        maskClosable={false}
        onCancel={() => {
          setCombined({ ...combined, combined: false });
        }}
        onOk={handleCombinedRoute}
      >
        Hệ thống nhận diện đơn kết hợp.
        <br />
        Cùng khách hàng <b>{combined.customer}</b> và số container <b>{combined.container}</b>
        <br />
        ngày{' '} {moment(combined.date).format('DD/MM/YYYY')}
        <br />
        <br />
        Chỉ số kết hợp doanh thu
        <Select
          onChange={(value) =>
            setCombined({ ...combined, combinedRate: value })
          }
          defaultValue={0.5}
          style={{ width: '100%' }}
        >
          {COMBINE_RATE.map((item: any) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Modal>
      <Modal
        title="Xác nhận giá cước đơn dịch vụ"
        open={showInputCustomPrice}
        okText="Xác nhận"
        cancelText="Đóng"
        maskClosable={true}
        onCancel={() => {
          setShowInputCustomPrice(false)
        }}
        onOk={() => handleUpdatePrice(expenseAmount)}
      >
        Giá cước cho xe dịch vụ:
        <Form
          fields={[
            {
              name: ["price"],
              value: expenseAmount,
            },
          ]}
        >
          <Form.Item name="price">
            <Input
              name={'price'}
              onChange={(e: any) => {
                setExpenseAmount(e.target.value);
              }}
              type="number"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        footer={false}
        title={`Kết quả`}
        open={importModal.visible}
        width={1000}
        onCancel={() => setImportModal({ ...importModal, visible: false })}
        onOk={() => setImportModal({ ...importModal, visible: false })}
      >
        <Title style={{ textAlign: "center", margin: "20px 0px" }} level={3}>
          Tải lên thành công {importModal.success.length}/{importModal.total}
          <br /> ({importModal.assigned} đã chỉ định nhà xe)
        </Title>
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <CheckCircleOutlined />
                Thành công ({importModal.success.length})
              </span>
            }
            key="1"
          >
            {importModal.success.length > 0 ? (
              <Table
                tableLayout="auto"
                scroll={{ x: "max-content" }}
                columns={importColumns}
                dataSource={importModal.success}
                pagination={false}
              />
            ) : (
              "Không có"
            )}
          </TabPane>
          <TabPane
            tab={
              <span>
                <CloseCircleOutlined />
                Thất bại ({importModal.errors.length})
              </span>
            }
            key="2"
          >
            {importModal.errors.length === importModal.total && (
              <>
                <Alert
                  message="Lưu ý"
                  description="Có thể file upload không đúng vói cấu trúc file quy định, sử dụng đúng mẫu file của AloTruck."
                  type="warning"
                  showIcon
                  closable
                />
                <br />
                <br />
              </>
            )}
            {importModal.errors.length > 0 ? (
              <Descriptions size="small" title="Các mục lỗi" bordered>
                {importModal &&
                  importModal.errors.map((error: any) => (
                    <Descriptions.Item span={3} label={error.stt}>
                      {error.error.map((item: any) => (
                        <>
                          {item}
                          <br />
                        </>
                      ))}
                    </Descriptions.Item>
                  ))}
              </Descriptions>
            ) : (
              "Không có"
            )}
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}
const StyleWrapper = styled.div`
  .anticon:hover {
    color: rgba(19, 194, 194, 1);
  }
`;
export default OrderWithRouteList;
