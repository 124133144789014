import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Form,
  InputNumber,
  Radio,
  Input,
  Button,
  Select,
} from "antd";
import {
  DirectionsRenderer,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { GOOGLE_MAP_KEY } from "../../../shared/key";
import { ListSearchLocation } from "../ListSearchLocation";
import { IPlace } from "../interface";
import { useHistory } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import API from "../../../shared/api/api";
import {
  rule_float_number,
  rule_number,
  rule_require,
} from "../../../shared/validateInput";
import _ from "lodash";

const { Title } = Typography;
const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};
function CreateRouteContractorWithPath() {
  const history = useHistory();
  const [listPlaces, setListPlaces] = useState<IPlace[]>([
    { lat: 0, lng: 0, position: "start" },
    { lat: 0, lng: 0, position: "end" },
  ]);

  const [list, setList] = useState<IPlace[]>([]);

  useEffect(() => {
    if (listPlaces) {
      const realList = listPlaces.filter((item) => item.lat && item.lng);
      setList(realList);
    }
  }, [listPlaces]);

  const containerStyle = {
    width: "100%",
    height: "100vh",
  };
  const [form] = Form.useForm();
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [combinationsCode, setCombinationsCode] = useState<any[]>([]);
  const [listDirection, setListDirection] = useState<any[]>([]);

  const [center, setCenter] = useState({
    lat: 21.028511,
    lng: 105.804817,
  });

  const setListDir = (dir: any, index: number) => {
    const temp = list.filter((item, index) => index > 0);
    temp[index] = dir;
    setListDirection(temp);
  };
  const calculateDir = (origin: IPlace, destination: IPlace, index: number) => {
    const directionService = new google.maps.DirectionsService();
    directionService.route(
      {
        destination: new google.maps.LatLng({
          lat: destination?.lat,
          lng: destination?.lng,
        }),
        origin: new google.maps.LatLng({
          lat: origin?.lat,
          lng: origin?.lng,
        }),
        travelMode: google.maps.TravelMode.DRIVING,
      },
      async (response, status) => {
        console.log("response", response);

        setListDir(response, index);
      }
    );
  };
  const handleChangeCode = (key: string, value: string) => {
    console.log('Key', key, "id", value)
    form.setFieldsValue({ code: key });
  };

  useEffect(() => {
    // let totalDistance = 0;
    setCombinationsCode([])
    if (list.length > 0) {
      list.map((item, index) => {
        if (item.lat && item.lng) {
          // totalDistance += item?.distance ?? 0;
          if (index > 0) {
            fetchOriginFreight(list[0]._id, list[list.length - 1]._id)
            calculateDir(list[index - 1], item, index - 1);
          }
        }
      });
    }
    // form.setFieldsValue({ distance: totalDistance });
    // setDistance(totalDistance);

    // const distance= new google.maps.DistanceMatrixService();
    // distance.getDistanceMatrix(
    //   {
    //     destinations: new google.maps.LatLng({
    //       lat: destination?.lat,
    //       lng: destination?.lng,
    //     }),
    //     origins: new google.maps.LatLng({
    //       lat: origin?.lat,
    //       lng: origin?.lng,
    //     }),
    //     travelMode: google.maps.TravelMode.DRIVING,
    //   },
    //   async (response, status) => {
    //     setListDir(response, index);
    //   }
    // );
  }, [list]);

  const [truckVendors, setTruckVendors] = useState([]);

  const onCreate = async () => {
    setLoadingBtn(true);
    const customData = { ...form.getFieldsValue() };
    const payload = {
      ...customData,
      receive: listPlaces[0]._id,
      delivery: listPlaces[listPlaces.length - 1]._id,
      path: listPlaces,
    };
    console.log("payload", payload);

    const response = await API.post("admin/contractorFreight", payload).then(
      (res) => res.data
    );
    if (response.success) history.push("/setting/priceContractor");
    setLoadingBtn(false);
  };

  const handleChangeTruckVendor = (value: string) => {
    form.setFieldsValue({ truckVendor: value });
  };

  useEffect(() => {
    fetchTruckVendor();
  }, []);

  const fetchTruckVendor = async () => {
    const response: any = await API.get("admin/truck-vendor/all").then(
      (res) => res.data
    );

    if (response.success) {
      if (response.data) {
        const result = response?.data
          .sort((a: any, b: any) => a.shortName.localeCompare(b.shortName))
          .map((item: any) => {
          return { label: `${item?.shortName}`, value: item?._id }; //(${item?.name})
        });
        setTruckVendors(result);
      }
    }
  };
  const fetchOriginFreight = async (receiptId: any, deliveryId: any) => {
    console.log('requestCombinationFreight:', receiptId, deliveryId)
    const searchParams = {
      receipt: receiptId,
      delivery: deliveryId,
    };
    const response: any = await API.get("admin/freight/combination", {
      params: searchParams,
    });
    const { data } = response;

    if (data.success) {
      let result: any[] = [];
      data?.data.forEach((item: any) => {
        if (item?.code && !result.includes(item?.code)) {
          result.push(item.code)
        }
      })
      const listFreightCode = result.map((item: any)=> {
        return { label: item, value: item };
      })
      // const result = data?.data.map((item: any) => {
      //   // return { label: `${item?.code} (${item?.customer?.name})`, value: item?._id };
      //   return { label: `${item?.code} (${item?.customer?.name})`, value: item?._id };
      // })
      setCombinationsCode(listFreightCode);
    }
  };

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
          <span
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined />
            Quay lại
          </span>
        </Title>

        <Row gutter={[4, 0]} style={{ width: "150vw" }}>
          <Col lg={24} xs={24} sm={16}>
            <Row>
              <Col
                xs={24}
                style={{ height: "100vh", border: "1px solid greyMa" }}
              >
                <Col xs={24}>
                  <LoadScript
                    googleMapsApiKey={GOOGLE_MAP_KEY}
                    libraries={["places"]}
                  >
                    <div style={{ display: "flex" }}>
                      <Col lg={6}>
                        <Col xs={24} sm={8}>
                          <Title level={5}>Lộ trình</Title>
                        </Col>
                        <ListSearchLocation
                          list={listPlaces}
                          setList={setListPlaces}
                          disabledMiddlePoint={true}
                        />
                        <Form
                          form={form}
                          {...layout}
                          //   style={{ width: '100%' }}
                          onFinish={onCreate}
                          onFinishFailed={() => {
                            setLoadingBtn(false);
                          }}
                          style={{ marginTop: 10 }}
                        >
                          {/*<Col lg={22} xs={24} sm={8}>*/}
                          {/*  <Form.Item label="Mã tuyến" name="code">*/}
                          {/*    <Input placeholder={"Nhập mã tuyến"}/>*/}
                          {/*  </Form.Item>*/}
                          {/*</Col>*/}
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item label="Mã tuyến" name="code">
                              <Select
                                // defaultValue="lucy"
                                style={{ width: "100%" }}
                                onChange={handleChangeCode}
                                options={combinationsCode}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item label="Mô tả" name="description">
                              <Input placeholder={"Nhập mô tả"}/>
                            </Form.Item>
                          </Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item label="Nhà thầu" name="truckVendors">
                              <Select
                                mode={'multiple'}
                                // defaultValue="lucy"
                                style={{ width: "100%" }}
                                onChange={handleChangeTruckVendor}
                                options={truckVendors}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={16}></Col>
                          <Col xs={24} sm={8}>
                            <Title level={5}>Giá cước</Title>
                          </Col>
                          <Col xs={0} sm={16}></Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Giá cước cont 20"
                              name={["price", "cont20"]}
                              rules={[rule_float_number]}
                            >
                              <InputNumber
                                formatter={(value) =>
                                  ` ${value
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                }
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Giá cước cont 40"
                              name={["price", "cont40"]}
                              rules={[rule_float_number]}
                            >
                              <InputNumber
                                formatter={(value) =>
                                  ` ${value
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                }
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm={8}></Col>

                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Giá CY cont 20"
                              name={["price", "cy20"]}
                              rules={[rule_float_number]}
                            >
                              <InputNumber
                                formatter={(value) =>
                                  ` ${value
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                }
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={22} xs={24} sm={8}>
                            <Form.Item
                              label="Giá CY cont 40"
                              name={["price", "cy40"]}
                              rules={[rule_float_number]}
                            >
                              <InputNumber
                                formatter={(value) =>
                                  ` ${value
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                }
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm={8}></Col>

                          <Col lg={22} xs={24} sm={6}>
                            <Form.Item
                              label="Giá cước xe tải"
                              name={["price", "truck"]}
                              rules={[rule_float_number]}
                            >
                              <InputNumber
                                formatter={(value) =>
                                  ` ${value
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                                }
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                          </Col>
                          {/* </Row> */}
                          <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={8}>
                            <Button
                              loading={isLoadingBtn}
                              htmlType="submit"
                              style={{
                                width: "100%",
                                backgroundColor: "#03A9F4",
                                color: "white",
                              }}
                            >
                              Thêm chặng
                            </Button>
                          </Col>
                        </Form>
                      </Col>
                      <div style={containerStyle}>
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          zoom={6}
                          center={center}
                        >
                          {/* {listPlaces && (
                            <>
                              {listPlaces.map((item) => {
                                if (item.polyline) {
                                  return (
                                    <Polyline
                                      path={decode(item?.polyline ?? '').map(
                                        (item) => {
                                          return {
                                            lat: item.latitude,
                                            lng: item.longitude,
                                          };
                                        }
                                      )}
                                      options={options}
                                    />
                                  );
                                }
                              })}
                            </>
                          )} */}
                          {listPlaces.length > 1 && (
                            <>
                              {listPlaces.map((item, index) => {
                                if (index > 0) {
                                  if (index === listPlaces.length - 1) {
                                    return (
                                      <Marker
                                        position={{
                                          lat: listPlaces[listPlaces.length - 1]
                                            ?.lat,
                                          lng: listPlaces[listPlaces.length - 1]
                                            ?.lng,
                                        }}
                                        icon="/icon/delivery.png"
                                      />
                                    );
                                  }
                                  return (
                                    <Marker
                                      position={{
                                        lat: item?.lat,
                                        lng: item?.lng,
                                      }}
                                      icon="/icon/marker.png"
                                    />
                                  );
                                }
                              })}
                            </>
                          )}

                          {listPlaces.length > 0 && (
                            <Marker
                              position={{
                                lat: listPlaces[0]?.lat,
                                lng: listPlaces[0]?.lng,
                              }}
                              icon="/icon/receipt.png"
                            />
                          )}

                          {listDirection.length > 0 && (
                            <>
                              {listDirection.map((item) => {
                                return (
                                  <DirectionsRenderer
                                    options={{
                                      directions: item,
                                      markerOptions: {
                                        icon: "/icon/receipt.png",
                                      },
                                      suppressMarkers: true,
                                    }}
                                  />
                                );
                              })}
                            </>
                          )}
                        </GoogleMap>
                      </div>
                    </div>
                  </LoadScript>
                </Col>
                {/* } */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </>
  );
}

export default CreateRouteContractorWithPath;
