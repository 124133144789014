import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import API from "../../../shared/api/api";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MinusOutlined,
  PlusOutlined,
  ReloadOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import {
  ORDER_STATUS_OPTIONS,
  PRESERVATION_OPTIONS,
  PRESERVATION_OPTIONS_ICON,
  SPECIFICATION_TYPE,
} from "../../../shared/define";
import moment from "moment";
import * as FileSaver from "file-saver";
import dayjs from 'dayjs';
import { duplicatePendingOrder } from "../../../shared/api/Order/PendingOrder";
import { getAllBOT } from "../../../shared/api/BOT";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

function HistoryOrder() {
  const [form] = Form.useForm();
  const [formIncurred] = Form.useForm();
  const [bot, setBot] = useState<any>([]);
  const [dataList, setDataList] = useState([]);
  const [forwarderList, setForwarderList] = useState([]);
  const [truckVendorList, setTruckVendorList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<any>({ status: "FINISH" });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [modal, setModal] = useState({
    visible: false,
  });
  const [numberOfFeet, setNumberOfFeet] = useState(0);
  const [type, setType] = useState("TRUCK_VENDOR");
  const [isPopup, setPopup] = useState(false);
  const [copyId, setCopyId] = useState("");
  const [copyNum, setCopyNum] = useState(0);
  const [incurredModal, setIncurredModal] = useState({
    visible: false,
    data: [],
    orderId: "",
    botType: "",
  });
  const [typeIncurred, setTypeIncurred] = useState<any>([]);
  const [hieuxuatModal, setHieuxuatModal] = useState<any>(false);
  const [hieuxuatRange, setHieuxuatRange] = useState<any>([
    dayjs().subtract(1, "month"),
    dayjs(),
  ]);
  const [hieuxuatType, setHieuxuatType] = useState<any>("ACTUAL");
  const [idIncurredEdit, setIdIncurredEdit] = useState<any>("");
  const [selectedRow, setSelectedRow] = useState<any>();

  useEffect(() => {
    fetchData();
  }, [filter, pagination.current, pagination.pageSize]);

  const fetchData = async () => {
    setLoading(true);
    const response: any = await API.get("admin/history-order", {
      params: {
        ...filter,
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
    }).then((res) => res.data);
    if (response.success) {
      const data = response.data;
      const customData = data.map((item: any, index: any) => ({
        ...item,
        key: index + 1,
      }));
      setDataList(customData);
      setPagination({ ...pagination, total: response.totalItem });
    }
    setLoading(false);
  };
  const saveOrder = async (id: any) => {
    setLoading(true);

    const response: any = await API.put(`admin/history-order/${id}/bookmark`, {
      params: {
        ...filter,
        current: pagination.current,
      },
    }).then((res) => res.data);
    if (response.success) fetchData();
  };

  useEffect(() => {
    // getForwarderList();
    getTruckVendorList();
    getCustomerList();
    getBot();
  }, []);
  const getBot = async () => {
    const res = await getAllBOT();
    if (res) setBot(res.data);
  };
  const getForwarderList = () => {
    API.get("admin/forwarder/all")
      .then((res) => {
        if (res.data.success) {
          setForwarderList(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCustomerList = () => {
    API.get("admin/customer/all")
      .then((res) => {
        if (res.data.success) {
          setCustomerList(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTruckVendorList = () => {
    API.get("admin/truck-vendor/all")
      .then((res) => {
        if (res.data.success) {
          setTruckVendorList(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadReport = async (values: any) => {
    let query = {
      range: [
        moment.utc(values?.range[0]).format("YYYY-MM-DD HH:mm:ssZ"),
        moment.utc(values?.range[1]).format("YYYY-MM-DD HH:mm:ssZ"),
      ],
      type: values.type,
      status: values.status,
      id: values.id,
    };
    await API.get("/admin/report/export", {
      params: query,
      responseType: "arraybuffer",
    }).then((response) => {
      var blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(
        blob,
        `report-${values.type.toLowerCase()}-${moment().format(
          "DD-MM-YYYY"
        )}.xlsx`
      );
    });
    setModal({ ...modal, visible: false });
  };

  const handleOk = async () => {
    const response: any = await duplicatePendingOrder({
      copyId: copyId,
      copyNum: copyNum,
    });
    if (response.success) setPopup(false);
  };
  const handleOkHieuxuat = async () => {
    const range = [
      hieuxuatRange?.[0].startOf("day"),
      hieuxuatRange?.[1].endOf("day"),
    ];
    console.log("hieuxuatRange:", hieuxuatRange)
    console.log("Ranged:", range)
    const res: any = await API.post(
      "/admin/report/efficiency",
      {
        range: range,
        type: hieuxuatType,
      },
      { responseType: "blob" }
    ).then((response) => {
      var blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(blob, `efficiency.xlsx`);
    });
    setHieuxuatRange([]);
    setHieuxuatModal(false);
  };
  const handleCancel = () => {
    setPopup(false);
    setCopyId("");
    setCopyNum(0);
  };
  const handleCancelHieuxuat = () => {
    setHieuxuatRange([]);
    setHieuxuatModal(false);
  };

  const closeIncurredModal = () => {
    setIncurredModal({ ...incurredModal, visible: false });
    setTypeIncurred([]);
    setNumberOfFeet(0);
    setIdIncurredEdit("");
    formIncurred.setFieldsValue({ incurreds: [] });
    setSelectedRow();
  };
  const addIncurreds = (values: any) => {
    API.post("admin/incurred", { ...values, order: incurredModal.orderId })
      .then((res) => {
        if (res.data.success) {
          closeIncurredModal();
          fetchData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateIncurreds = (values: any) => {
    API.put(`admin/incurred/${idIncurredEdit}`, { ...values.incurreds?.[0] })
      .then((res) => {
        if (res.data.success) {
          closeIncurredModal();
          fetchData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteIncurreds = (id: any) => {
    API.delete(`admin/incurred/${id}`)
      .then((res) => {
        if (res.data.success) {
          const dataSource = [...incurredModal.data];
          setIncurredModal({
            ...incurredModal,
            data: dataSource.filter((item: any) => item._id !== id),
          });
          fetchData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const columns: Array<object> = [
    {
      title: "STT",
      dataIndex: "key",
      width: 40,
      render: (key: any, row: any) => <Tooltip title={row.code}>{key}</Tooltip>,
    },
    {
      title: "Ngày",
      dataIndex: ["date"],
      render: (date: any, record: any) => moment(date ?? record?.receipt?.[0]?.time).format("DD/MM/YYYY"),
    },
    {
      title: "Biển số xe",
      dataIndex: "routes",
      render: (route: any) =>
        route?.[0]?.vehicle ? route?.[0]?.vehicle?.licensePlate : "29H-32154", //TODO remove when demo done
    },
    {
      title: "Chủ xe",
      dataIndex: ["truckVendor", "shortName"],
    },
    {
      title: "Khách hàng",
      dataIndex: ["customer", "name"],
      render: (name: any) => (
        <Tooltip placement="topLeft" title={name}>
          <div className="address">{name}</div>
        </Tooltip>
      ),
    },
    {
      title: "N/X/B",
      dataIndex: "routes",
      render: (route: any) => {
        const type = SPECIFICATION_TYPE.find(
          (el: any) => el.value === route?.[0].specification
        );
        return <>{type?.shortLabel}</>;
      },
    },
    {
      title: "Đặc tính",
      dataIndex: ["goods", "preservation"],
      render: (option: any) => {
        const tooltip = PRESERVATION_OPTIONS.find(
          (element: any) => (element as any).value === option
        )?.label;
        const icon = PRESERVATION_OPTIONS_ICON.find(
          (element: any) => (element as any).value === option
        )?.label;
        return (
          <Tooltip placement="topLeft" title={tooltip} arrowPointAtCenter>
            <img src={icon} />
          </Tooltip>
        );
      },
    },
    {
      title: "Loại xe",
      dataIndex: ["vehicle", "numberOfFeets"],
    },
    {
      title: "Số cont",
      dataIndex: "routes",
      render: (route: any) => <>{route?.[0].container}</>,
    },
    // {
    //   title: "Mã điểm nhận",
    //   dataIndex: "receipt",
    //   render: (receipt: any) => <div>{receipt?.[0]?.area?.code}</div>,
    // },
    {
      title: "Điểm nhận",
      dataIndex: "receipt",
      render: (receipt: any) => (
        <Tooltip
          placement="topLeft"
          title={`${receipt?.[0]?.area?.name}--${receipt?.[0]?.area?.pName}`}
        >
          <div className="address">
            {receipt?.[0]?.area?.name}-{receipt?.[0]?.area?.pName}
          </div>
        </Tooltip>
      ),
    },
    // {
    //   title: "Mã điểm trả",
    //   dataIndex: "delivery",
    //   render: (delivery: any) => <div>{delivery?.[0]?.area?.code}</div>,
    // },
    {
      title: "Điểm trả",
      dataIndex: "delivery",
      render: (delivery: any) => (
        <Tooltip
          placement="topLeft"
          title={`${delivery?.[0]?.area?.name}--${delivery?.[0]?.area?.pName}`}
        >
          <div className="address">
            {delivery?.[0]?.area?.name}-{delivery?.[0]?.area?.pName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Km có hàng",
      // dataIndex: ["freight", "haveGoodsDistance"],
      dataIndex: "routes",
      render: (route: any) => <>{route?.[0].haveGoodsDistance}</>,
    },
    {
      title: "Km không hàng",
      // dataIndex: ["freight", "noGoodsDistance"],
      dataIndex: "routes",
      render: (route: any) => <>{route?.[0].noGoodsDistance}</>,
    },
    {
      title: "Km phát sinh",
      dataIndex: "routes",
      render: (route: any) => <>{route?.[0].incurredDistance}</>,
    },
    {
      title: "Tỉ lệ kết hợp doanh thu",
      dataIndex: "routes",
      render: (route: any) => <>{route?.[0].combinedRate}</>,
    },
    {
      title: "Tỉ lệ kết hợp chi phí",
      dataIndex: "routes",
      render: (route: any) => <>{route?.[0].combinedExpenseRate}</>,
    },
    {
      title: "Giá cước",
      dataIndex: "price",
      align: "right",
      render: (price: any, record: any) => (
        <>{new Intl.NumberFormat().format(price)}đ</>
      ),
    },
    {
      title: "Giá cước trả xe",
      dataIndex: "price",
      align: "right",
      render: (price: any, record: any) => (
        <>{new Intl.NumberFormat().format(price)}đ</>
      ),
    },
    {
      title: "CPPS dự kiến",
      dataIndex: "incurreds",
      align: "right",
      render: (incurreds: any, record: any) => {
        let amount = 0;
        incurreds.forEach((incurred: any) => {
          if (incurred.verified && incurred.type === "PLAN")
            amount += incurred.amount;
        });
        return (
          <Space>
            <div>{new Intl.NumberFormat().format(amount)}đ</div>
            {/* <Tooltip title="Cập nhật">
                        <EditOutlined onClick={() => setIncurredModal({...incurredModal, data: incurreds, visible: true, orderId: record._id})} style={{ fontSize: 16, color: '#3fa3ff' }} />
                    </Tooltip> */}
          </Space>
        );
      },
    },
    {
      title: "CPPS phải thu",
      dataIndex: "incurreds",
      align: "right",
      render: (incurreds: any, record: any) => {
        let amount = 0;
        incurreds.forEach((incurred: any) => {
          amount += incurred.amount;
        });
        return (
          <Space>
            <div>{new Intl.NumberFormat().format(amount)}đ</div>
            <Tooltip title="Cập nhật">
              <EditOutlined
                onClick={() => {
                  setNumberOfFeet(
                    record?.routes?.[0]?.vehicle?.type?.numberOfFeets
                  );
                  setSelectedRow(record);
                  setIncurredModal({
                    ...incurredModal,
                    data: incurreds,
                    visible: true,
                    botType: record?.type,
                    orderId: record._id,
                  });
                }}
                style={{ fontSize: 16, color: "#3fa3ff" }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: "CPPS phải chi",
      dataIndex: "incurreds",
      align: "right",
      render: (incurreds: any) => {
        let total = 0;
        incurreds.map((el: any) => {
          if (el.verified) total += el.amount;
        });
        return <div>{new Intl.NumberFormat().format(total)}đ</div>;
      },
    },
    {
      title: "PAKD",
      dataIndex: "bookmark",
      render: (bookmark: any, record: any) =>
        bookmark ? (
          <Button type="text">
            <img
              style={{
                height: "18px",
                width: "14px",
                marginRight: "10px",
                cursor: "pointer",
              }}
              src="/icon/saved.png"
              onClick={() => saveOrder(record._id)}
            />
          </Button>
        ) : (
          <Button type="text">
            <img
              style={{
                height: "18px",
                width: "14px",
                marginRight: "10px",
                cursor: "pointer",
              }}
              src="/icon/save.png"
              onClick={() => saveOrder(record._id)}
            />
          </Button>
        ),
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      render: (option: any) => {
        const obj = ORDER_STATUS_OPTIONS.find(
          (element: any) => (element as any).value === option
        );
        return obj?.label;
      },
    },
    {
      title: "Tác vụ",
      render: (order: any) => (
        <>
          <Tooltip title="Nhân bản">
            <CopyOutlined
              style={{ fontSize: 16 }}
              onClick={() => {
                setPopup(true);
                setCopyId(order._id);
              }}
            />
          </Tooltip>
          <Tooltip title="Sửa">
            <Link
              to={{
                pathname: `/order/history/edit/${order._id}`,
              }}
            >
              <EditOutlined style={{ fontSize: 16 }} />
            </Link>
          </Tooltip>
        </>
      ),
    },
  ];
  const incurredColumns = [
    {
      title: "STT",
      dataIndex: "_id",
      width: 50,
      render: (id: any, record: any, index: any) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "title",
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      render: (description: any) => (description ? description : "Không có"),
    },
    {
      title: "Giá tiền",
      dataIndex: "amount",
      render: (amount: any) =>
        (amount ? new Intl.NumberFormat().format(amount) : 0) + " đ",
    },
    {
      title: "Loại",
      dataIndex: "type",
      render: (type: any) => (type === "PLAN" ? "Dự kiến" : "Thực tế"),
    },
    {
      title: "Dạng chi phí",
      dataIndex: "source",
      render: (type: any) =>
        type === "DEFAULT" ? "Mặc định" : "Trạm cầu đường",
    },
    {
      title: "Trạng thái",
      dataIndex: "verified",
      render: (verified: any) => (verified ? "Đã duyệt" : "Chưa duyệt"),
    },
    {
      title: "Tác vụ",
      render: (item: any) => (
        <Space size="middle">
          <Tooltip title="Xóa">
            <DeleteOutlined
              style={{ fontSize: 16, color: "red" }}
              onClick={() => {
                deleteIncurreds(item._id);
              }}
            />
          </Tooltip>
          {/* <Tooltip title="Sửa">
                        <EditOutlined style={{ fontSize: 16, color: '#3fa3ff' }} onClick={() => { { setIdIncurredEdit(item._id); formIncurred.setFieldsValue({ incurreds: [{ ...item, verified: item.verified?.toString() }] }) } }} />
                    </Tooltip> */}
        </Space>
      ),
    },
  ];
  const onChangeTypeIncurred = (index: any, value: any) => {
    const clonetype = [...typeIncurred];
    const cloneStage = [...formIncurred.getFieldValue("incurreds")];
    clonetype[index] = value;
    if (value == "BOT") {
      cloneStage[index].verified = "true";
      formIncurred.setFieldsValue({ incurreds: cloneStage });
    }
    setTypeIncurred(clonetype);
  };
  const onChangeBot = (index: any, value: any) => {
    const cloneIncurred = [...formIncurred.getFieldValue("incurreds")];
    const found = bot.find((element: any) => element._id == value);
    cloneIncurred[index].title = found.name;
    if (numberOfFeet == 20) {
      cloneIncurred[index].amount = found.turn.twenty;
    }
    if (numberOfFeet == 40) {
      cloneIncurred[index].amount = found.turn.forty;
    }
    formIncurred.setFieldsValue({ incurreds: cloneIncurred });
  };
  const onDeleteType = (index: any) => {
    const clonetype = [...typeIncurred];
    clonetype.splice(index, 1);
    setTypeIncurred(clonetype);
  };
  const addType = () => {
    const clonetype = [...typeIncurred];
    clonetype.push("DEFAULT");
    setTypeIncurred(clonetype);
  };
  return (
    <>
      <Modal
        destroyOnClose={true}
        closable={false}
        title="Nhân bản đơn"
        visible={isPopup}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Text>Số lượng muốn nhân bản</Text>
        <Input
          onChange={(e: any) => {
            setCopyNum(e.target.value);
          }}
          type="number"
        />
        <Text>
          Lưu ý: Đơn hàng khi đã được nhân bản sẽ được gán các mã đơn khác nhau
        </Text>
      </Modal>
      <Modal
        destroyOnClose={true}
        closable={false}
        title="Tải hiệu xuất"
        visible={hieuxuatModal}
        onOk={handleOkHieuxuat}
        onCancel={handleCancelHieuxuat}
      >
        <RangePicker
          // size="small"
          name="range"
          style={{ width: 350 }}
          ranges={{
            "Tháng này": [
              dayjs().startOf("month"),
              dayjs().endOf("month"),
            ],
            "Tháng trước": [
              dayjs().subtract(1, "month").startOf("month"),
              dayjs().subtract(1, "month").endOf("month"),
            ],
          }}
          defaultValue={[
            dayjs().subtract(1, "month"),
            dayjs(),
            // moment(moment().add(-1, "months"), "DD/MM/YYYY"),
            // moment(moment(), "DD/MM/YYYY"),
          ]}
          onChange={(value: any) => {
            setHieuxuatRange(value)}
          }
          format="DD/MM/YYYY"
        />
        <br />
        <br />
        Loại
        <br />
        <Select
          defaultValue="ACTUAL"
          onChange={(value: any) => setHieuxuatType(value)}
        >
          <Select.Option value="ACTUAL">Thực tế</Select.Option>
          <Select.Option value="PLAN">Kế hoạch</Select.Option>
        </Select>
      </Modal>
      <Title level={4}>
        Lịch sử đơn ({pagination.total}){" "}
        <Tooltip title="Danh sách các đơn hàng có trạng thái Hoàn thành hoặc Bị hủy. Nhấn vào biểu tượng Bookmark để lưu lại thành Phương án kinh doanh">
          <InfoCircleOutlined style={{ color: "#0082fb" }} />
        </Tooltip>
      </Title>
      <Row justify="space-between" gutter={6}>
        <Col>
          <Space size={6}>
            {/*<Select*/}
            {/*  showSearch*/}
            {/*  size="small"*/}
            {/*  style={{ width: 220 }}*/}
            {/*  placeholder="Forwarder"*/}
            {/*  optionFilterProp="children"*/}
            {/*  filterOption={(input, option: any) =>*/}
            {/*    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
            {/*  }*/}
            {/*  dropdownMatchSelectWidth={false}*/}
            {/*  onSelect={(value) => setFilter({ ...filter, forwarder: value })}*/}
            {/*>*/}
            {/*  <Option value="ALL">Tất cả</Option>*/}
            {/*  {forwarderList.map((forwarder: any) => (*/}
            {/*    <Option key={forwarder._id} value={forwarder._id}>*/}
            {/*      {forwarder.shortName}*/}
            {/*    </Option>*/}
            {/*  ))}*/}
            {/*</Select>*/}
            <Select
              showSearch
              size="small"
              style={{ width: 220 }}
              placeholder="Nhà xe"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownMatchSelectWidth={false}
              onSelect={(value) => setFilter({ ...filter, truckVendor: value })}
            >
              <Option value="ALL">Tất cả</Option>
              {truckVendorList.map((truckVendor: any) => (
                <Option key={truckVendor._id} value={truckVendor._id}>
                  {truckVendor.shortName}
                </Option>
              ))}
            </Select>

            <Select
              size="small"
              placeholder="Trạng thái"
              style={{ width: 120 }}
              onChange={(value) => setFilter({ ...filter, status: value })}
              defaultValue="FINISH"
            >
              <Option value="ALL">Tất cả</Option>
              <Option value="FINISH">Hoàn thành</Option>
              <Option value="CANCEL">Bị hủy</Option>
            </Select>

            {/* <Select placeholder="Loại hàng" size="small" style={{ width: 120 }} onChange={(value) => setFilter({ ...filter, preservation: value })}>
                            <Option value="ALL">Tất cả</Option>
                            <Option value="NORMAL">Thường</Option>
                            <Option value="COLD">Lạnh</Option>
                            <Option value="DANGER">Nguy hiểm</Option>
                            <Option value="OOG">Quá khổ</Option>
                        </Select> */}

            <RangePicker
              size="small"
              style={{ width: 250 }}
              onChange={(date, dateString) =>
                setFilter({
                  ...filter,
                  range: [
                    moment.utc(date?.[0]).format("YYYY-MM-DD HH:mm:ssZ"),
                    moment.utc(date?.[1]).format("YYYY-MM-DD HH:mm:ssZ"),
                  ],
                })
              }
              ranges={{
                "Tháng này": [
                  dayjs().startOf("month"),
                  dayjs().endOf("month"),
                ],
                "Tháng trước": [
                  dayjs().subtract(1, "month").startOf("month"),
                  dayjs().subtract(1, "month").endOf("month"),
                ],
              }}
            />

            <Select
              style={{ width: 80 }}
              size="small"
              defaultValue={pagination.pageSize}
              onSelect={(value: number) =>
                setPagination({ ...pagination, pageSize: value })
              }
            >
              {[20, 40, 60, 80, 100].map((pageSize: any) => (
                <Option key={pageSize} value={pageSize}>
                  {pageSize}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>
        <Space size={6}>
          <Button
            size="small"
            onClick={fetchData}
            icon={<ReloadOutlined />}
          ></Button>
          <Search
            placeholder="Số container"
            // disabled
            allowClear
            size="small"
            style={{ width: 200, margin: "0 10px" }}
            onSearch={(value) => setFilter({ ...filter, searchText: value })}
          />
          {/* <Button type="primary" icon={<PlusOutlined />}>
                        <Link to={{
                                pathname: '/order/pending/create',
                            }} style={{ color: 'white' }}>
                                Tạo mới
                        </Link>
                    </Button> */}
          {/* <Popover placement="bottomLeft" content={
                        <>
                            <Button onClick={() => downloadReport('TRUCK_VENDOR')} style={{width: '100%', marginBottom: 5}}>Chủ xe</Button><br />
                            <Button onClick={() => downloadReport('FORWARDER')} style={{width: '100%'}}>Chủ hàng</Button>
                        </>
                    } title="Chọn loại" trigger="click"> */}
          <Button
            size="small"
            type="primary"
            onClick={() => setHieuxuatModal(true)}
          >
            Tải hiệu xuất
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => setModal({ ...modal, visible: true })}
          >
            Tải báo cáo
          </Button>
          {/* </Popover> */}
        </Space>
      </Row>
      <br />
      <Table
        scroll={{ x: "max-content" }}
        loading={loading}
        columns={columns}
        pagination={pagination.total > pagination.pageSize && pagination}
        dataSource={dataList}
        onChange={(value: any) =>
          setPagination({ ...pagination, current: value.current })
        }
      />
      <Modal
        width={600}
        destroyOnClose={true}
        closable={true}
        title="Tải báo cáo"
        visible={modal.visible}
        onCancel={() => setModal({ ...modal, visible: false })}
        onOk={() => {
          form.submit();
        }}
        okText={
          <>
            <VerticalAlignBottomOutlined />
            Tải xuống
          </>
        }
        cancelText="Hủy"
      >
        <Form {...layout} form={form} onFinish={downloadReport}>
          <Form.Item label="Báo cáo" name="type" initialValue="TRUCK_VENDOR">
            <Radio.Group
              onChange={(e) => {
                setType(e.target.value);
                form.setFieldsValue({ ...form.getFieldsValue(), id: null });
              }}
            >
              <Radio value="TRUCK_VENDOR">Nhà xe</Radio>
              {/* <Radio value="FORWARDER" >Chủ hàng</Radio>
                            <Radio value="CUSTOMER">Khách hàng</Radio> */}
            </Radio.Group>
          </Form.Item>
          {type === "TRUCK_VENDOR" && (
            <Form.Item
              label="Nhà xe"
              name="id"
              rules={[{ required: true, message: "Vui lòng chọn nhà xe!" }]}
            >
              <Select
                showSearch
                size="small"
                style={{ width: 250 }}
                placeholder="Nhà xe"
                dropdownMatchSelectWidth={false}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={(value) =>
                  setFilter({ ...filter, truckVendor: value })
                }
              >
                {truckVendorList.map((truckVendor: any) => (
                  <Option key={truckVendor._id} value={truckVendor._id}>
                    {truckVendor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {type === "FORWARDER" && (
            <Form.Item
              label="Chủ hàng"
              name="id"
              rules={[{ required: true, message: "Vui lòng chọn chủ hàng!" }]}
            >
              <Select
                showSearch
                size="small"
                style={{ width: 250 }}
                placeholder="Chọn chủ hàng"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={(value) =>
                  setFilter({ ...filter, truckVendor: value })
                }
              >
                {forwarderList.map((item: any) => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {type === "CUSTOMER" && (
            <Form.Item
              label="Khách hàng"
              name="id"
              rules={[{ required: true, message: "Vui lòng chọn khách hàng!" }]}
            >
              <Select
                showSearch
                size="small"
                style={{ width: 250 }}
                placeholder="Chọn khách hàng"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={(value) =>
                  setFilter({ ...filter, truckVendor: value })
                }
              >
                {customerList.map((item: any) => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label="Thời gian"
            name="range"
            initialValue={[moment().startOf("month"), moment().endOf("month")]}
          >
            <RangePicker
              // size="small"
              style={{ width: 250 }}
              ranges={{
                "Tháng này": [
                  dayjs().startOf("month"),
                  dayjs().endOf("month")
                  // moment().startOf("month"),
                  // moment().endOf("month"),
                ],
                "Tháng trước": [
                  dayjs().subtract(1, "month").startOf("month"),
                  dayjs().subtract(1, "month").endOf("month")
                ],
              }}
              // format='DD/MM/YYYY'
            />
          </Form.Item>
          {/* <Form.Item
                        label="Trạng thái"
                        name="status"
                        initialValue="FINISH"
                    >
                        <Radio.Group>
                            <Radio value="FINISH">Thành công</Radio>
                            <Radio value="ALL">Tất cả</Radio>
                        </Radio.Group>
                    </Form.Item> */}
        </Form>
      </Modal>
      <Modal
        width={800}
        destroyOnClose={true}
        closable={true}
        title="Chi phí phát sinh"
        visible={incurredModal.visible}
        onCancel={closeIncurredModal}
        onOk={() => {
          formIncurred.submit();
        }}
      >
        <Table
          bordered={false}
          columns={incurredColumns}
          dataSource={incurredModal.data}
          pagination={false}
        />
        <br />
        <Form
          layout="vertical"
          form={formIncurred}
          onFinish={idIncurredEdit ? updateIncurreds : addIncurreds}
        >
          <Form.List name="incurreds">
            {(fields, { add, remove }) => {
              console.log("thai", form.getFieldValue("routes"));

              return (
                <>
                  {fields.map((field) => (
                    <Row
                      style={{
                        borderBottom: "1px solid #eee",
                        marginBottom: 20,
                        padding: 10,
                      }}
                      gutter={8}
                    >
                      <Col span={8}>
                        <Form.Item
                          label="Tên"
                          name={[field.name, "title"]}
                          rules={[
                            {
                              required: true,
                              message: "Tên phát sinh là bắt buộc",
                            },
                          ]}
                        >
                          <Input
                            {...(typeIncurred[field.name] == "BOT" && {
                              disabled: true,
                            })}
                            placeholder="Tên phát sinh"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Chi phí"
                          name={[field.name, "amount"]}
                          rules={[
                            { required: true, message: "Chi phí là bắt buộc" },
                          ]}
                        >
                          <Input
                            {...(typeIncurred[field.name] == "BOT" &&
                              incurredModal.botType === "OPEN_BOT" && {
                                disabled: true,
                              })}
                            placeholder="Số tiền"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Dạng chi phí"
                          name={[field.name, "source"]}
                          initialValue="COLLECT"
                        >
                          <Select
                            onChange={(value: any) =>
                              onChangeTypeIncurred(field.name, value)
                            }
                          >
                            <Option value="COLLECT">Phải thu</Option>
                            <Option value="PAY">Phải chi</Option>
                            <Option value="BOT">Trạm cầu đường</Option>
                            <Option value="COD">Thu hộ chi hộ</Option>
                            {selectedRow?.routes?.[0]?.specification ===
                              "ROAD" && (
                              <Option value="BOTS">
                                Cầu đường (đường dài)
                              </Option>
                            )}
                            <Option value="OTHER">Khác</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Loại"
                          name={[field.name, "type"]}
                          initialValue="ACTUAL"
                        >
                          <Select disabled>
                            <Option value="PLAN">Dự kiến</Option>
                            <Option value="ACTUAL">Thực tế</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      {typeIncurred[field.name] == "BOT" && (
                        <Col span={8}>
                          <Form.Item
                            label="Trạm thu phí"
                            // name={[field.name, 'bot']}
                            rules={[
                              {
                                required: true,
                                message: "Trạm thu phí là bắt buộc",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              onChange={(value: any) =>
                                onChangeBot(field.name, value)
                              }
                              filterOption={(input, option: any) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {bot.map((item: any, index: any) => (
                                <Option key={index} value={item._id}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      )}

                      <Col span={8}>
                        <Form.Item
                          label="Trạng thái"
                          name={[field.name, "verified"]}
                          initialValue="true"
                        >
                          <Select
                            {...(type[field.name] == "BOT" && {
                              disabled: true,
                            })}
                          >
                            <Option value="true">Duyệt</Option>
                            <Option value="false">Không duyệt</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      {typeIncurred[field.name] == "DEFAULT" && (
                        <Col span={8}>
                          <Form.Item
                            label="Mô tả"
                            name={[field.name, "description"]}
                          >
                            <Input.TextArea rows={1} placeholder="Mô tả" />
                          </Form.Item>
                        </Col>
                      )}

                      <Col span={24}>
                        <Form.Item>
                          <Button
                            style={{ width: "100%" }}
                            danger
                            type="dashed"
                            onClick={() => {
                              remove(field.name);
                              onDeleteType(field.name);
                              if (idIncurredEdit) setIdIncurredEdit("");
                            }}
                            block
                            icon={<MinusOutlined />}
                          ></Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  {!idIncurredEdit && (
                    <Form.Item>
                      <Button
                        style={{ width: "100%" }}
                        type="dashed"
                        onClick={() => {
                          add();
                          addType();
                        }}
                        block
                        icon={<PlusOutlined />}
                      ></Button>
                    </Form.Item>
                  )}
                </>
              );
            }}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
}

export default HistoryOrder;
