import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Divider,
  Select,
  Typography,
  Spin,
  InputNumber,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import API from "../../../shared/api/api";
import { dateFormatYMD } from "../../../shared/dataFormat";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import {
  ACTIVE_TYPE,
  ASSIGN_TYPE,
  NUMBER_OF_AXLES_OPTION,
  NUMBER_OF_FEETS_OPTIONS,
  OWNERSHIP_TYPE,
  PAYLOAD_OPTION,
  PRESERVATION_OPTIONS,
  VEHICLE_OPTION,
} from "../../../shared/define";
import {
  rule_float_number,
  rule_number,
  rule_require,
  rule_not_require
} from "../../../shared/validateInput";
import InterestModal from "../CreateVehicle/InterestModal";
import MomentDatePicker from "../../../Components/MomentDatePicker";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const { Title } = Typography;
const { Option } = Select;
function UpdateVehicle() {
  const OWNERSHIP_OWNER = OWNERSHIP_TYPE[0].value;

  const [form] = Form.useForm();
  const [formInterest] = Form.useForm();
  const [vendorList, setVendorList] = useState([]);
  const [driverList, setListDriver] = useState([]);
  const [vehicleType, setVehicleType] = useState("");
  const [spinning, setSpinning] = useState(true);
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [ownership, setOwnership] = useState(OWNERSHIP_TYPE[0].value);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const history = useHistory();
  let { id }: any = useParams();

  useEffect(() => {
    getListVendor();
    loadData();
  }, []);
  const loadData = () => {
    setSpinning(true);
    API.get(`admin/vehicle/${id}`)
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data;
          setVehicleType(listData?.type.name);
          getListDriver(listData?.truckVendor._id);
          setOwnership(listData?.ownership);
          form.setFieldsValue({
            ...listData,
            registerDate: moment(listData?.registerDate),
            registrationExpireDate: moment(listData?.registrationExpireDate),
            physicalDamageCoverageExpireDate: moment(
              listData?.physicalDamageCoverageExpireDate
            ),
            civilLiabilityInsuranceExpireDate: moment(
              listData?.civilLiabilityInsuranceExpireDate
            ),
            transportBadgeExpireDate: moment(
              listData?.transportBadgeExpireDate
            ),
            manufacturingYearDate: moment(listData?.manufacturingYearDate),
          });
          formInterest.setFieldsValue({ interest: listData.interest });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSpinning(false);
      });
  };
  const getListVendor = () => {
    API.get("admin/truck-vendor/all")
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data;
          listData.forEach((item: { key: any; _id: any }) => {
            item.key = item._id;
          });
          setVendorList(listData.sort((a: any, b: any) => a.shortName.localeCompare(b.shortName)));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getListDriver = (id: string) => {
    API.get(`admin/truck-vendor/${id}/drivers`)
      .then((res) => {
        if (res.data.success) {
          const listData = res.data.data.drivers;
          listData.forEach((item: { key: any; _id: any }) => {
            item.key = item._id;
          });
          setListDriver(listData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onUpdate = async () => {
    setLoadingBtn(true);
    let customdata = {
      ...form.getFieldsValue(),
      ...formInterest.getFieldsValue(),
      registerDate: dateFormatYMD(form.getFieldsValue().registerDate),
      registrationExpireDate: dateFormatYMD(
        form.getFieldsValue().registrationExpireDate
      ),
      physicalDamageCoverageExpireDate: dateFormatYMD(
        form.getFieldsValue().physicalDamageCoverageExpireDate
      ),
      civilLiabilityInsuranceExpireDate: dateFormatYMD(
        form.getFieldsValue().civilLiabilityInsuranceExpireDate
      ),
      transportBadgeExpireDate: dateFormatYMD(
        form.getFieldsValue().transportBadgeExpireDate
      ),
      manufacturingYearDate: dateFormatYMD(
        form.getFieldsValue().manufacturingYearDate
      ),
    };
    const response = await API.put(`admin/vehicle/${id}`, customdata).then(
      (res) => res.data
    );
    if (response.success) history.push("/resource/vehicle");
    setLoadingBtn(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  return (
    <Row style={{ width: "100%" }}>
      <Spin spinning={spinning}>
        <InterestModal
          form={formInterest}
          visible={isModalVisible}
          setVisible={(e: boolean) => setIsModalVisible(e)}
        />
        <Title level={5} style={{ marginBottom: "25px", cursor: "pointer" }}>
          <span
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined />
            Quay lại
          </span>
        </Title>
        <Form
          form={form}
          {...layout}
          style={{ width: "100%" }}
          onFinish={onUpdate}
          onFinishFailed={() => {
            setLoadingBtn(false);
          }}
        >
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Đơn vị sở hữu xe"
                name={["truckVendor", "_id"]}
                rules={[rule_require]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn nhà xe"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(id: string) => getListDriver(id)}
                >
                  {vendorList.map((vendor: any) => (
                    <Option key={vendor._id} value={vendor._id}>
                      {vendor.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Tên lái xe trong hệ thống"
                name={["driver", "_id"]}
                rules={[ownership === OWNERSHIP_OWNER ? rule_require : rule_not_require]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Chọn tài xế"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {driverList.map((driver: any) => (
                    <Option key={driver._id} value={driver._id}>
                      {driver.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Biển số xe"
                name="licensePlate"
                rules={[rule_require]}
              >
                <Input placeholder="Nhập biển số xe" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={4} md={4} lg={4} xl={4}>
              <Form.Item
                label="Loại xe"
                name={["type", "name"]}
                rules={[ownership === OWNERSHIP_OWNER ? rule_require : rule_not_require]}
              >
                <Select
                  placeholder="Chọn loại xe"
                  onSelect={(type: string) => setVehicleType(type)}
                >
                  {VEHICLE_OPTION.map((item: any, index: any) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {vehicleType === "CONTAINER" && (
              <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                <Form.Item
                  label="Số trục"
                  name={["type", "numberOfAxles"]}
                  rules={[ownership === OWNERSHIP_OWNER ? rule_require : rule_not_require]}
                >
                  <Select placeholder="Chọn số trục">
                    {NUMBER_OF_AXLES_OPTION.map((item: any, index: any) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {vehicleType === "CONTAINER" && (
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Kết cấu vỏ"
                  name="preservation"
                  rules={[ownership === OWNERSHIP_OWNER ? rule_require : rule_not_require]}
                >
                  <Select placeholder="Chọn vỏ">
                    {PRESERVATION_OPTIONS.map((item: any, index: any) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {vehicleType === "CONTAINER" && (
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Loại Container"
                  name={["type", "numberOfFeets"]}
                  rules={[ownership === OWNERSHIP_OWNER ? rule_require : rule_not_require]}
                >
                  <Select placeholder="Chọn Loại Container">
                    {NUMBER_OF_FEETS_OPTIONS.map((item: any, index: any) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {vehicleType === "TRUCK" && (
              <Col xs={24} sm={4}>
                <Form.Item
                  label="Tải trọng"
                  name={["type", "payload"]}
                  rules={[ownership === OWNERSHIP_OWNER ? rule_require : rule_not_require]}
                >
                  <Select placeholder="Chọn tải trọng">
                    {PAYLOAD_OPTION.map((item: any, index: any) => (
                      <Option key={index} value={item.value}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col xs={24} sm={8}>
              <Form.Item
                label="Năm sản xuất"
                name="manufacturingYearDate"
                rules={[ownership === OWNERSHIP_OWNER ? rule_require : rule_not_require]}
              >
                <MomentDatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Trạng thái xe"
                name="isActive"
                rules={[rule_require]}
              >
                <Select>
                  {ACTIVE_TYPE.map((item: any) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Divider style={{ margin: "14px" }} />

            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Hình thức sở hữu"
                name="ownership"
                rules={[rule_require]}
              >
                <Select onSelect={(value: any) => setOwnership(value)}>
                  {OWNERSHIP_TYPE.map((item: any) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {ownership === OWNERSHIP_TYPE[0].value && (
              <>
                <Col xs={24} sm={8}>
                  <Form.Item
                    label="Giá trị"
                    name="price"
                    rules={[rule_number, rule_require]}
                  >
                    <InputNumber
                      formatter={(value) =>
                        ` ${value
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={4}>
                  <Form.Item
                    label="Thời gian khấu hao"
                    name="amortizationPeriod"
                    rules={[rule_number, rule_require]}
                  >
                    <Input suffix="Tháng" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={4}>
                  <Form.Item
                    label="Khoán"
                    name="isAssign"
                    rules={[rule_require]}
                  >
                    <Select>
                      {ASSIGN_TYPE.map((item: any, index: any) => (
                        <Option key={index} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
            {ownership === OWNERSHIP_TYPE[1].value && (
              <>
                <Col xs={24} sm={4}>
                  <Form.Item
                    label="Phí thuê khô theo tháng"
                    name="dryRentalFeePerMonth"
                    rules={[rule_number, rule_require]}
                  >
                    <InputNumber
                      formatter={(value) =>
                        ` ${value
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {ownership === OWNERSHIP_TYPE[2].value && (
              <>
                <Col xs={24} sm={4}>
                  <Form.Item
                    label="Hoa hồng mỗi đơn hàng"
                    name="commissionPercentagePerRoute"
                    rules={[rule_float_number, rule_require]}
                  >
                    <Input suffix="%" />
                  </Form.Item>
                </Col>
              </>
            )}
            {ownership === OWNERSHIP_TYPE[3].value && (
              <>
                <Col xs={24} sm={4}>
                  <Form.Item
                    label="Phí điều hành vận tải"
                    name="directOperatingPerRoute"
                    rules={[rule_number, rule_require]}
                  >
                    <InputNumber
                      formatter={(value) =>
                        ` ${value
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            <Divider style={{ margin: "14px" }} />
            <Col xs={24} sm={8}>
              <Form.Item
                label="Mức dầu: 0 Tấn"
                name={["oil", "equal0"]}
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Mức dầu: <20 Tấn"
                name={["oil", "from0to20"]}
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Mức dầu: 20-25 Tấn"
                name={["oil", "from20to25"]}
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Mức dầu: >25 Tấn"
                name={["oil", "moreThan25"]}
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Thể tích bình dầu"
                name={"oilTankVolume"}
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Divider style={{ margin: "14px" }} />
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời gian đăng ký"
                name="registerDate"
                rules={[ownership === OWNERSHIP_OWNER ? rule_require : rule_not_require]}
              >
                <MomentDatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Giá tiền"
                name="registerPrice"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời gian khấu hao"
                name="registerAmortization"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <Input suffix="Tháng" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Hạn đăng kiểm"
                name="registrationExpireDate"
                rules={[ownership === OWNERSHIP_OWNER ? rule_require : rule_not_require]}
              >
                <MomentDatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Giá tiền"
                name="registrationPrice"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời gian khấu hao"
                name="registrationAmortization"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <Input suffix="Tháng" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Hạn bảo hiểm vật chất"
                name="physicalDamageCoverageExpireDate"
                rules={[ownership === OWNERSHIP_OWNER ? rule_require : rule_not_require]}
              >
                <MomentDatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Giá tiền"
                name="physicalDamageCoveragePrice"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời gian khấu hao"
                name="physicalDamageCoverageAmortization"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <Input suffix="Tháng" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Hạn bảo hiểm nhân sự"
                name="civilLiabilityInsuranceExpireDate"
                rules={[ownership === OWNERSHIP_OWNER ? rule_require : rule_not_require]}
              >
                <MomentDatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Giá tiền"
                name="civilLiabilityInsurancePrice"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời gian khấu hao"
                name="civilLiabilityInsuranceAmortization"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <Input suffix="Tháng" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Hạn phù hiệu"
                name="transportBadgeExpireDate"
                rules={[ownership === OWNERSHIP_OWNER ? rule_require : rule_not_require]}
              >
                <MomentDatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Giá tiền"
                name="transportBadgePrice"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời gian khấu hao"
                name="transportBadgeAmortization"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <Input suffix="Tháng" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Giá bến bãi theo tháng"
                name="parkingPrice"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8}>
              <Form.Item
                label="Lệ phí đường bộ"
                name="roadTollPrice"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <InputNumber
                  formatter={(value) =>
                    ` ${value
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={8} md={8} lg={8} xl={8}>
              <Form.Item
                label="Thời gian khấu hao"
                name="roadTollAmortization"
                rules={ownership === OWNERSHIP_OWNER ? [rule_number, rule_require] : [rule_number]}
              >
                <Input suffix="Tháng" />
              </Form.Item>
            </Col>
            <Col xs={6} sm={4}>
              <Button
                onClick={showModal}
                size="small"
                style={{
                  width: "100%",
                  height: 30,
                  backgroundColor: "#009688",
                  borderColor: "#009688",
                }}
                type="primary"
              >
                Nhập lãi vay
              </Button>
            </Col>
          </Row>
          <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16}>
            <Button
              loading={isLoadingBtn}
              htmlType="submit"
              style={{
                width: "100%",
                backgroundColor: "#03A9F4",
                color: "white",
              }}
            >
              Cập nhật
            </Button>
          </Col>
        </Form>
      </Spin>
    </Row>
  );
}

export default UpdateVehicle;
